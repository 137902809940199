<template>
  <nav>
    <button class="nav-button" @class="{'is-active': navContent}" @click="navContent = !navContent;"><img src="@/assets/img/nav-button.png" loading="lazy" alt="コンテンツ一覧"></button>
    <transition name="nav-fade">
      <div class="nav" v-show="navContent">
        <button class="nav-close" @click="navContent = !navContent;"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
        <div class="nav-content modal-scroll">
          <div class="nav-wrap">
            <div class="nav-left">
              <img class="nav-title" src="@/assets/img/nav-world.png" loading="lazy" alt="世界観">
              <ul class="nav-list">
                <li><a href="/event/history/">・ヒストリー</a></li>
                <li><router-link to="/world/" @click="navContent = !navContent;">・プロローグ</router-link></li>
                <li><router-link to="/world/#character" @click="navContent = !navContent;">・キャラクター</router-link></li>
                <li><router-link to="/world/#world" @click="navContent = !navContent;">・ワールド</router-link></li>
              </ul>
            </div>
            <div class="nav-right">
              <img class="nav-title" src="@/assets/img/nav-system.png" loading="lazy" alt="ゲームシステム">
              <ul class="nav-list">
                <li><router-link to="/system/" @click="navContent = !navContent;">・ストーリー</router-link></li>
                <li><router-link to="/system/#battle" @click="navContent = !navContent;">・英雄紹介</router-link></li>
                <li><router-link to="/system/#raid" @click="navContent = !navContent;">・レイドバトル</router-link></li>
                <li><router-link to="/system/#openfield" @click="navContent = !navContent;">・オープンフィールドエリア</router-link></li>
              </ul>
            </div>
          </div>
          <ul class="nav-list nav-flex">
            <li class="nav-flex-left"><a href="/webtoon/">・Webコミック</a></li>
            <li class="nav-flex-right"><a href="//forum.netmarble.com/sk2_jp">・公式フォーラム</a></li>
          </ul>
          <div class="nav-preregistration"><router-link to="/#preregistration" @click="navContent = !navContent;"><img src="@/assets/img/button-click.png" loading="lazy" alt="事前登録はこちらから"></router-link></div>
          <ul class="nav-sns">
            <li><a href="//twitter.com/Sena2_JP" target="_blank" rel="noopener"><svg><use xlink:href="#iconTwitter"></use></svg></a></li>
            <li><a href="//www.youtube.com/channel/UCrRdcVCaEMNqE2xWgDCXdUg/" target="_blank" rel="noopener"><svg><use xlink:href="#iconYoutube"></use></svg></a></li>
          </ul>
        </div>
      </div>
    </transition>
  </nav>
</template>

<style lang="scss">
.nav-button {
  height: 10.3rem;
  position: fixed;
  right: 2.7rem;
  top: 2rem;
  width: 10.3rem;
  z-index: 100;
  @media (min-width: 829px) {
    height: 6.2rem;
    width: 6.2rem;
  }
}

.nav {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.nav-close {
  position: absolute;
  right: 3.6rem;
  top: 3.3rem;
  width: 9.5rem;
  z-index: 1;
  @media (min-width: 829px) {
    height: 3.9rem;
    right: 4.1rem;
    top: 4.8rem;
    width: 6.3rem;
  }
}

.nav-content {
  background: #0d1317;
  padding: 40.4rem 0 5.7rem;
  @media (min-width: 829px) {
    padding: 25.3rem 0 19.1rem;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
  }
  &::before {
    background: url('~@/assets/img/nav-background-sp.jpg') no-repeat 0 0 / 100%;
    height: 140.7rem;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    @media (min-width: 829px) {
      background: url('~@/assets/img/nav-background-pc.jpg') no-repeat 50% 50% / auto 100%;
      height: 108rem;
    }
  }
  &::after {
    animation: flash 6s infinite;
    background: url('~@/assets/img/nav-background-light.png') no-repeat 0 0 / 100% 100%;
    height: 44.6rem;
    left: 31.9rem;
    mix-blend-mode: overlay;
    top: -6.3rem;
    width: 18.7rem;
    @media (min-width: 829px) {
      bottom: 0;
      height: 50.8rem;
      left: calc(50% - 52rem);
      margin: auto;
      top: calc(50% - 30rem);
      width: 21.3rem;
    }
  }
}

@keyframes flash {
  0% {
    opacity: .5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .5;
  }
}

.nav-wrap {
  display: flex;
  justify-content: center;
  margin-left: 5rem;
  position: relative;
  @media (min-width: 829px) {
    justify-content: start;
    margin-left: calc(50% + 4rem);
  }
  &::before {
    background: url('~@/assets/img/nav-line.png') no-repeat 0 0 / 100% 100%;
    bottom: -13.3rem;
    content: '';
    display: block;
    height: .5rem;
    left: -5rem;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 56.7rem;
    @media (min-width: 829px) {
      bottom: -6.2rem;
      height: .5rem;
      left: 2.1rem;
      margin: 0;
      width: 43.6rem;
    }
  }
}

.nav-right {
  margin-left: 2rem;
  @media (min-width: 829px) {
    margin-left: 8rem;
  }
}

.nav-title {
  height: 7rem;
  width: auto;
  @media (min-width: 829px) {
    height: 6.4rem;
  }
}

.nav-list {
  margin: 1rem 0 0 2.6rem;
  @media (min-width: 829px) {
    margin: .2rem 0 0 2rem;
  }
  li {
    font-size: 2.6rem;
    line-height: 1.846;
    @media (min-width: 829px) {
      font-size: 2rem;
    }
    a,
    router-link {
      border-bottom: 1px solid #ddbaa7;
      color: #ddbaa7;
      padding: 0 .5em .7rem 0;
      text-decoration: none;
      @media (min-width: 829px) {
        padding: 0 .2em .6rem 0;
      }
    }
    ~ li {
      margin-top: 5rem;
      @media (min-width: 829px) {
        margin-top: 3.6rem;
      }
    }
  }
  .nav-wrap + & {
    width: 60rem;
    margin: 19rem auto 0;
    display: flex;
    padding-left: 5rem;
    position: relative;
    @media (min-width: 829px) {
      margin: 9.6rem 0 0 calc(50% + 4rem);
      width: 50.8rem;
      padding-left: 2rem;
    }
    li {
      margin-top: 0;
    }
    .nav-flex-right {
      margin-left: 4.3rem;
      @media (min-width: 829px) {
        margin-left: 9.6rem;
      }
    }
  }
}

.nav-preregistration {
  line-height: 1 !important;
  margin: 5.6rem auto 0 !important;
  width: 52rem;
  @media (min-width: 829px) {
    margin: 3.1rem 0 0 calc(50% + 12rem) !important;
    width: 30.4rem;
  }
  a {
    border: 0 !important;
    padding: 0 !important;
  }
}

.nav-sns {
  display: flex;
  justify-content: center;
  margin-top: 8rem;
  @media (min-width: 829px) {
    margin: 5.3rem 0 0 calc(50% + 4rem);
    width: 50rem;
  }
  li {
    height: 3.7rem;
    overflow: hidden;
    position: relative;
    width: 4.5rem;
    @media (min-width: 829px) {
      height: 3rem;
      width: 3.7rem;
    }
    svg {
      bottom: 0;
      fill: #8d7272;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 4.5rem;
      @media (min-width: 829px) {
        width: 3.7rem;
      }
    }
    ~ li {
      margin-left: 4.3rem;
      @media (min-width: 829px) {
        margin-left: 5.3rem;
      }
    }
  }
}
</style>

<script>
export default {
  name: 'nav',
  data() {
    return {
      scrollY: 0,
      navContent: false
    }
  },
  methods: {
    calculateScrollY() {
      scrollY = window.scrollY
    },
    scrollFixed() {
      const body = document.body
      this.calculateScrollY()
      body.style.top = -scrollY + 'px'
      body.classList.add('is-fixed')
    },
    scrollActive() {
      const body = document.body
      body.style.top = 0
      body.classList.remove('is-fixed')
      document.documentElement.scrollTop = scrollY
    },
  },
  watch: {
    navContent() {
      if (this.navContent == true) {
        this.scrollFixed()
      } else {
        this.scrollActive()
      }
    }
  }
}
</script>