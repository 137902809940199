<template>
  <div class="system">
    <div class="content-wrap">
      <section id="story">
        <h1>
          <picture>
            <source media="(min-width: 829px)" srcset="@/assets/img/story-title-pc.png">
            <img src="@/assets/img/story-title-sp.png" loading="lazy" alt="ストーリー">
          </picture>
        </h1>
        <iframe class="system-movie" src="//www.youtube.com/embed/Uyl-VjD4_mg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <h2 class="system-headline"><img src="@/assets/img/story-headline.png" loading="lazy" alt="シネマティックストーリー"></h2>
        <p class="system-text">映画をみていると錯覚するような演出で<br>
          ストーリーが展開します。</p>
      </section>
      <section id="battle">
        <h1>
          <picture>
            <source media="(min-width: 829px)" srcset="@/assets/img/battle-title-pc.png">
            <img src="@/assets/img/battle-title-sp.png" loading="lazy" alt="英雄紹介">
          </picture>
        </h1>
        <iframe class="system-movie" src="//www.youtube.com/embed/njqyfxxuzUc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <h2 class="system-headline"><img src="@/assets/img/battle-headline.png" loading="lazy" alt="歴史の始まりと共に現れた英雄たち"></h2>
        <p class="system-text">魅力あふれる英雄を動かす楽しさはもちろんのこと、<br>
          華麗なキャラクタースキルは視覚的にも楽しませてくれます。</p>
      </section>
      <section id="raid" class="fadeIn">
        <h1>
          <picture>
            <source media="(min-width: 829px)" srcset="@/assets/img/raid-title-pc.png">
            <img src="@/assets/img/raid-title-sp.png" loading="lazy" alt="レイドバトル">
          </picture>
        </h1>
        <iframe class="system-movie" src="//www.youtube.com/embed/i0WiRFHLbXc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <h2 class="system-headline"><img src="@/assets/img/raid-headline.png" loading="lazy" alt="多人数マルチプレイで討伐"></h2>
        <p class="system-text">最大8人でのマルチプレイが可能！<br>
        仲間と力を合わせ、共に強力なボスを討伐しよう！</p>
      </section>
      <section id="openfield" class="fadeIn">
        <h1>
          <picture>
            <source media="(min-width: 829px)" srcset="@/assets/img/openfield-title-pc.png">
            <img src="@/assets/img/openfield-title-sp.png" loading="lazy" alt="OpenField オープンフィールドエリア">
          </picture>
        </h1>
        <iframe class="system-movie" src="//www.youtube.com/embed/uPpRe0fXPHA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <h2 class="system-headline"><img src="@/assets/img/openfield-headline.png" loading="lazy" alt="他プレイヤーと交流"></h2>
        <p class="system-text">忙しい時間にもオートプレイで強くなれる。<br>
        装備品の獲得や他プレイヤーとの交流が楽しめる！</p>
      </section>
    </div>
    <div class="background" v-if="isMobile() == 'ios'">
      <img class="video" src="@/assets/video/sparks.gif" loading="lazy" alt="">
    </div>
    <div class="background" v-else-if="isMobile() == 'android'">
      <video src="@/assets/video/sparks.mp4" playsinline muted autoplay loop></video>
    </div>
    <div class="background" v-else>
      <video src="@/assets/video/sparks.mp4" playsinline muted autoplay loop></video>
    </div>
  </div>
  <Sticky/>
</template>

<style lang="scss">
.system {
  background: #191818;
  color: #e6e5e2;
  font-size: 2.4rem;
  padding: 23.7rem 0 6rem;
  @media (min-width: 829px) {
    font-size: 1.4rem;
    padding-top: 10.2rem;
  }
  section {
    @media (min-width: 829px) {
      width: 42.8rem;
    }
    &:nth-child(2n) {
      @media (min-width: 829px) {
        margin-left: 3.8rem;
      }
    }
    &:nth-child(2) ~ section {
      @media (min-width: 829px) {
        margin-top: 7rem;
      }
    }
  }
  h1 {
    margin: 16rem auto 0;
    @media (min-width: 829px) {
      margin: 0 auto;
    }
  }
  h2 {
    margin: 4rem auto 0;
  }
  .content-wrap {
    //margin: 0 auto;
    @media (min-width: 829px) {
      margin: 0 auto;
      width: 89.4rem;
    }
  }
}

.system-movie {
  border: 1px solid #73674f;
  display: block;
  height: 41.6rem;
  margin: 5.9rem auto 0;
  width: 73.9rem;
  @media (min-width: 829px) {
    height: 24.1rem;
    margin-top: 3rem;
    width: 42.8rem;
  }
}

.system-headline {
  width: 56.7rem;
  @media (min-width: 829px) {
    width: 32.9rem;
  }
}

.system-text {
  line-height: 2.75;
  margin-top: 1.6rem;
  text-align: center;
  @media (min-width: 829px) {
    line-height: 2.571;
    margin-top: 1rem;
  }
}

/* story */

#story h1 {
  margin-top: 0;
  width: 55.7rem;
  @media (min-width: 829px) {
    width: 33.2rem;
  }
}

/* battle */

#battle h1 {
  width: 54rem;
  @media (min-width: 829px) {
    width: 33.2rem;
  }
  + .system-movie {
    @media (min-width: 829px) {
      margin-top: 2.6rem;
    }
  }
}

#battle .system-headline {
  width: 69rem;
  @media (min-width: 829px) {
    width: 44.8rem;
  }
}

/* raid */

#raid h1 {
  width: 55.7rem;
  @media (min-width: 829px) {
    margin-bottom: .1rem;
    width: 33.2rem;
  }
}

/* openfield */

#openfield h1 {
  width: 77.8rem;
  @media (min-width: 829px) {
    width: 40.4rem;
  }
}

</style>

<script>
import Sticky from '@/components/Sticky.vue'

const userAgent = navigator.userAgent

export default {
  components: {
    Sticky
  },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
        if (userAgent.match(/iPhone/i) || userAgent.match(/iPad/i) || userAgent.match(/iPod/i)) {
          return 'ios'
        } else {
          return 'android'
        }
      } else {
        return false
      }
    }
  }
}
</script>
