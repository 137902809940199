<template>
  <div id="app">
    <Loading v-show="loading"></Loading>
    <Header/>
    <Nav/>
    <router-view/>
    <Footer/>
  </div>
</template>

<style lang="scss">
@import url('//fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400&family=Noto+Serif+JP:wght@400&display=swap');

html {
  background: #000;
  font: 400 1.207vw / 1 'Noto Serif JP', 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'メイリオ', Osaka, 'MS PGothic', sans-serif;
  @media (min-width: 829px) {
    font-size: 42%;
    margin: 0 auto;
    max-width: 1920px;
  }
  @media (min-width: 950px) {
    font-size: 48%;
  }
  @media (min-width: 1080px) {
    font-size: 55%;
  }
  @media (min-width: 1180px) {
    font-size: 62.5%;
  }
}

body {
  font-size: 2.2rem;
  position: relative;
  -webkit-text-size-adjust: 100%;
  @media (min-width: 829px) {
    font-size: 1.4rem;
  }
}

p {
  line-height: 1.5;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    @media (min-width: 829px) {
      text-decoration: none;
    }
  }
}

picture,
img {
  display: block;
  width: 100%;
}

label,
button {
  cursor: pointer;
}

button {
  background: transparent;
}

::selection{
  background: rgba(180, 160, 118, .5);
}

/* common */

.is-fixed {
  position: fixed;
  width: 100%;
}

.section-wrap {
  @media (min-width: 829px) {
    width: 103.8rem;
    margin: 6rem auto 0;
    display: flex;
    justify-content: space-between;
  }
}

.content-wrap {
  @media (min-width: 829px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.youtube {
  border: 1px solid #73674f;
  display: block;
  height: 41.6rem !important;
  margin: 0 auto;
  width: 74rem !important;
  @media (min-width: 829px) {
    height: 25.9rem !important;
    width: 46rem !important;
  }
  iframe {
    height: 100% !important;
    width: 100% !important;
  }
}

.br {
  display: block;
  @media (min-width: 829px) {
    display: none;
  }
}

.background {
  height: 110%;
  left: 0;
  mix-blend-mode: screen;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
  .video {
    height: 100%;
    width: auto;
    @media (min-width: 829px) {
      height: 160%;
      opacity: .5;
    }
  }
  video {
    height: 100%;
    width: auto;
    @media (min-width: 829px) {
      height: 160%;
      opacity: .5;
    }
  }
}

/* modal */

.modal,
.modal-background {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.modal {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 2000;
  .box {
    @media (min-width: 829px) {
      width: 74rem;
    }
  }
}

.modal-scroll {
  height: 100%;
  left: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100%;
}

.modal-close {
  display: block;
  margin: 4rem auto 0;
  width: 39.6rem;
  @media (min-width: 829px) {
    width: 27.9rem;
  }
}

.modal-prev,
.modal-next {
  bottom: 0;
  margin: auto 0;
  position: absolute;
  top: 0;
  width: 7rem;
  @media (min-width: 829px) {
    width: 6.9rem;
  }
}

.modal-prev {
  left: 1rem;
}

.modal-next {
  right: 1rem;
}

.modal-background {
  background: rgba(20, 20, 20, .9);
  position: absolute;
  z-index: -1;
}

/* transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.nav-fade-enter-active,
.nav-fade-leave-active {
  transition: opacity 1s ease;
}

.nav-fade-enter-from,
.nav-fade-leave-to {
  opacity: 0;
}

.fadeIn {
  opacity: 0;
  transform: translate3d(0, 2rem, 0);
  transition: transform 1s, opacity 1s;
  @media (min-width: 829px) {
    transform: translate3d(0, 1rem, 0);
  }
  &.is-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

</style>

<script>
import Loading from '@/components/Loading.vue'
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'app',
  components: {
    Loading,
    Header,
    Nav,
    Footer
  },
  data() {
    return {
      loading: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000)

    const scrollEvent = function () {
      const target = document.getElementsByClassName('fadeIn')
      const position = Math.floor(window.innerHeight * .75)
      for (let i = 0; i < target.length; i ++) {
        let offsetTop = Math.floor(target[i].getBoundingClientRect().top);
        if (offsetTop < position) {
          target[i].classList.add('is-animate');
        }
      }
    }
    window.addEventListener('scroll', scrollEvent, false);
  }
}
</script>
