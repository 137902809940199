<template>
  <div class="world">
    <section id="prologue">
      <h1><img src="@/assets/img/prologue-title-sp.png" loading="lazy" alt="プロローグ"></h1>
      <p class="prologue-text">降臨の日から20年後、<span class="br"></span>セブンナイツをはじめとする英雄たちは姿を消していた。<br>
      魔物の群れは日増しに増え、テラ王国にある<span class="br"></span>王国の発電所にある破壊石を狙って押し寄せていた。<br>
      黎明の傭兵団のレンは魔物の討伐に向かうが、<span class="br"></span>破壊の力を吸収して、巨大化した魔物の力に苦しめられる。<br>
      レンが倒れる寸前のところで、謎の少女が現れ、<span class="br"></span>不思議な力を使って変異した魔物を消し去った。<br>
      レンと謎の少女、この二人の運命的な出会いによって<span class="br"></span>新たなる冒険の物語が始まろうとしていた…</p>
    </section>
    <section id="character" class="fadeIn">
      <h1><img src="@/assets/img/character-title-sp.png" loading="lazy" alt="キャラクター"></h1>
      <ul class="character-list">
        <li><a class="character-list-lene" href="#" @click="openLene"><img src="@/assets/img/character-thumbnail01.png" loading="lazy" alt="レン"></a></li>
        <li><a class="character-list-lukey" href="#" @click="openLukey"><img src="@/assets/img/character-thumbnail02.png" loading="lazy" alt=""></a></li>
        <li><a class="character-list-kade" href="#" @click="openKade"><img src="@/assets/img/character-thumbnail03.png" loading="lazy" alt=""></a></li>
        <li><a class="character-list-chengchen" href="#" @click="openChengchen"><img src="@/assets/img/character-thumbnail04.png" loading="lazy" alt=""></a></li>
        <li><a class="character-list-rudy" href="#" @click="openRudy"><img src="@/assets/img/character-thumbnail05.png" loading="lazy" alt=""></a></li>
        <li><a class="character-list-eileene" href="#" @click="openEileene"><img src="@/assets/img/character-thumbnail06.png" loading="lazy" alt=""></a></li>
      </ul>
    </section>
    <div class="character-lene character-detail modal" v-show="showLene">
      <img class="character-vertical" src="@/assets/img/character-title-vertical.png" loading="lazy" alt="Character">
      <div class="modal-scroll">
        <div class="character-detail-left">
          <h2><img src="@/assets/img/character-lene-title.png" loading="lazy" alt="黎明の希望 レン CV：瀬戸麻沙美"></h2>
          <picture class="character-visual">
            <source media="(min-width: 829px)" srcset="@/assets/img/character-lene-visual-pc.png">
            <img src="@/assets/img/character-lene-visual-sp.png" loading="lazy" alt="レン">
          </picture>
        </div>
        <div class="character-detail-right">
          <img class="character-text" src="@/assets/img/character-lene-text.png" loading="lazy" alt="「みんな、私のことを認めるようになるから！」">
          <p>アイリーンの遺志を引き継ぎ、黎明の傭兵団のリーダーに<br>
          なった少女。セブンナイツのアイリーンの娘として生まれ、<br>
          傭兵団員として育った。</p>
          <ul class="character-detail-img">
            <li><img src="@/assets/img/character-lene-thumbnail01.jpg" loading="lazy" alt="レン2D" @click="showLene2d = true"></li>
            <li><img src="@/assets/img/character-lene-thumbnail02.jpg" loading="lazy" alt="レン3D" @click="showLene3d = true"></li>
            <!-- <li><img src="@/assets/img/hero-movie.jpg" loading="lazy" alt="" @click="showLeneMovie = true"></li> -->
            <!-- <li><img src="@/assets/img/hero-movie.jpg" loading="lazy" alt=""></li> -->
            <li><iframe class="movie-iframe" src="//www.youtube.com/embed/Uer5kD7viOU?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
          </ul>
        </div>
      </div>
      <button class="modal-prev" @click="showLene = false; showEileene = true; stopVideo()"><img src="@/assets/img/icon-prev.png" loading="lazy" alt="prev"></button>
      <button class="modal-next" @click="showLene = false; showLukey = true; stopVideo()"><img src="@/assets/img/icon-next.png" loading="lazy" alt="next"></button>
      <button class="modal-close" @click="closeCharacter"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background"></div>
    </div>
    <div class="character-detail-visual modal" v-show="showLene2d">
      <img src="@/assets/img/character-lene-visual-2d.jpg" loading="lazy" alt="レン">
      <button class="modal-close" @click="showLene2d = false"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background" @click="showLene2d = false"></div>
    </div>
    <div class="character-detail-visual modal" v-show="showLene3d">
      <img src="@/assets/img/character-lene-visual-3d.jpg" loading="lazy" alt="レン">
      <button class="modal-close" @click="showLene3d = false"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background" @click="showLene3d = false"></div>
    </div>
    <!-- <div class="character-detail-visual modal" v-show="showLeneMovie">
      <YouTube class="movie movie-lene" src="//www.youtube.com/watch?v=Uer5kD7viOU?enablejsapi=1" ref="youtube" :player-vars="playerVars" @state-change="onStateChange"/>
      <button class="modal-close" @click="showLeneMovie = false; stopVideo()"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background" @click="stopVideo"></div>
    </div> -->
    <div class="character-lukey character-detail modal" v-show="showLukey">
      <img class="character-vertical" src="@/assets/img/character-title-vertical.png" loading="lazy" alt="Character">
      <div class="modal-scroll">
        <div class="character-detail-left">
          <h2><img src="@/assets/img/character-lukey-title.png" loading="lazy" alt="勝負師 ルーキー 日野聡"></h2>
          <picture class="character-visual">
            <source media="(min-width: 829px)" srcset="@/assets/img/character-lukey-visual-pc.png">
            <img src="@/assets/img/character-lukey-visual-sp.png" loading="lazy" alt="ルーキー">
          </picture>
        </div>
        <div class="character-detail-right">
          <img class="character-text" src="@/assets/img/character-lukey-text.png" loading="lazy" alt="「幸運の女神に祈ってみろよ。」">
          <p>レンと一緒に行動する勝負師。<br>
            路地裏のギャンブル場でコソ泥をしながら渡り歩いていたが、<br>
            ギャンブル場でジョーカーに出会い、彼の弟子になる。</p>
          <ul class="character-detail-img">
            <li><img src="@/assets/img/character-lukey-thumbnail01.jpg" loading="lazy" alt="ルーキー2D" @click="showLukey2d = true"></li>
            <li><img src="@/assets/img/character-lukey-thumbnail02.jpg" loading="lazy" alt="ルーキー3D" @click="showLukey3d = true"></li>
            <li><iframe class="movie-iframe" src="//www.youtube.com/embed/cLqBxbBfn3w?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
          </ul>
        </div>
      </div>
      <button class="modal-prev" @click="showLukey = false; showLene = true; stopVideo()"><img src="@/assets/img/icon-prev.png" loading="lazy" alt="prev"></button>
      <button class="modal-next" @click="showLukey = false; showKade = true; stopVideo()"><img src="@/assets/img/icon-next.png" loading="lazy" alt="next"></button>
      <button class="modal-close" @click="closeCharacter"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background"></div>
    </div>
    <div class="character-detail-visual modal" v-show="showLukey2d">
      <img src="@/assets/img/character-lukey-visual-2d.jpg" loading="lazy" alt="ルーキー">
      <button class="modal-close" @click="showLukey2d = false"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background" @click="showLukey2d = false"></div>
    </div>
    <div class="character-detail-visual modal" v-show="showLukey3d">
      <img src="@/assets/img/character-lukey-visual-3d.jpg" loading="lazy" alt="ルーキー">
      <button class="modal-close" @click="showLukey3d = false"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background" @click="showLukey3d = false"></div>
    </div>
    <div class="character-kade character-detail modal" v-show="showKade">
      <img class="character-vertical" src="@/assets/img/character-title-vertical.png" loading="lazy" alt="Character">
      <div class="modal-scroll">
        <div class="character-detail-left">
          <h2><img src="@/assets/img/character-kade-title.png" loading="lazy" alt="精鋭戦士 ケイド CV：松田健一郎"></h2>
          <picture class="character-visual">
            <source media="(min-width: 829px)" srcset="@/assets/img/character-kade-visual-pc.png">
            <img src="@/assets/img/character-kade-visual-sp.png" loading="lazy" alt="ケイド">
          </picture>
        </div>
        <div class="character-detail-right">
          <img class="character-text" src="@/assets/img/character-kade-text.png" loading="lazy" alt="「俺が先頭に立つ！みんな頑張れ！」">
          <p>獣人の父親と人間の母親の間に生まれた混血。<br>
            獣人部隊の新兵だったが、獣人部隊の全滅により行き場を失って<br>
            彷徨っていたところ、アイリーンの協力によって黎明の傭兵団に<br>
            入団することになった。</p>
          <ul class="character-detail-img">
            <li><img src="@/assets/img/character-kade-thumbnail01.jpg" loading="lazy" alt="ケイド2D" @click="showKade2d = true"></li>
            <li><img src="@/assets/img/character-kade-thumbnail02.jpg" loading="lazy" alt="ケイド3D" @click="showKade3d = true"></li>
            <li><iframe class="movie-iframe" src="//www.youtube.com/embed/wxN4rrfpn9M?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
          </ul>
        </div>
      </div>
      <button class="modal-prev" @click="showKade = false; showLukey = true; stopVideo()"><img src="@/assets/img/icon-prev.png" loading="lazy" alt="prev"></button>
      <button class="modal-next" @click="showKade = false; showChengchen = true; stopVideo()"><img src="@/assets/img/icon-next.png" loading="lazy" alt="next"></button>
      <button class="modal-close" @click="closeCharacter"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background"></div>
    </div>
    <div class="character-detail-visual modal" v-show="showKade2d">
      <img src="@/assets/img/character-kade-visual-2d.jpg" loading="lazy" alt="ケイド">
      <button class="modal-close" @click="showKade2d = false"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background" @click="showKade2d = false"></div>
    </div>
    <div class="character-detail-visual modal" v-show="showKade3d">
      <img src="@/assets/img/character-kade-visual-3d.jpg" loading="lazy" alt="ケイド">
      <button class="modal-close" @click="showKade3d = false"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background" @click="showKade3d = false"></div>
    </div>
    <div class="character-chengchen character-detail modal" v-show="showChengchen">
      <img class="character-vertical" src="@/assets/img/character-title-vertical.png" loading="lazy" alt="Character">
      <div class="modal-scroll">
        <div class="character-detail-left">
          <h2><img src="@/assets/img/character-chengchen-title.png" loading="lazy" alt="波動の龍牙 チェンチェン CV：佐倉綾音"></h2>
          <picture class="character-visual">
            <source media="(min-width: 829px)" srcset="@/assets/img/character-chengchen-visual-pc.png">
            <img src="@/assets/img/character-chengchen-visual-sp.png" loading="lazy" alt="チェンチェン">
          </picture>
        </div>
        <div class="character-detail-right">
          <img class="character-text" src="@/assets/img/character-chengchen-text.png" loading="lazy" alt="「チェンチェン、用意できました！」">
          <p>幼い頃住んでいた村が襲撃され、家族や隣人を全員失った。<br>
            その後、修道僧のリーに助けられ弟子になって武道家として育つ。<br>
            時々的外れなことを言うロマンチックな少女。</p>
          <ul class="character-detail-img">
            <li><img src="@/assets/img/character-chengchen-thumbnail01.jpg" loading="lazy" alt="チェンチェン2D" @click="showChengchen2d = true"></li>
            <li><img src="@/assets/img/character-chengchen-thumbnail02.jpg" loading="lazy" alt="チェンチェン3D" @click="showChengchen3d = true"></li>
            <li><iframe class="movie-iframe" src="//www.youtube.com/embed/9KAeNOw2-bA?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
          </ul>
        </div>
      </div>
      <button class="modal-prev" @click="showChengchen = false; showKade = true; stopVideo()"><img src="@/assets/img/icon-prev.png" loading="lazy" alt="prev"></button>
      <button class="modal-next"><img src="@/assets/img/icon-next.png" loading="lazy" alt="next" @click="showChengchen = false; showRudy = true; stopVideo()"></button>
      <button class="modal-close" @click="closeCharacter"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background"></div>
    </div>
    <div class="character-detail-visual modal" v-show="showChengchen2d">
      <img src="@/assets/img/character-chengchen-visual-2d.jpg" loading="lazy" alt="チェンチェン">
      <button class="modal-close" @click="showChengchen2d = false"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background" @click="showChengchen2d = false"></div>
    </div>
    <div class="character-detail-visual modal" v-show="showChengchen3d">
      <img src="@/assets/img/character-chengchen-visual-3d.jpg" loading="lazy" alt="チェンチェン">
      <button class="modal-close" @click="showChengchen3d = false"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background" @click="showChengchen3d = false"></div>
    </div>
    <div class="character-rudy character-detail modal" v-show="showRudy">
      <img class="character-vertical" src="@/assets/img/character-title-vertical.png" loading="lazy" alt="Character">
      <div class="modal-scroll">
        <div class="character-detail-left">
          <h2><img src="@/assets/img/character-rudy-title.png" loading="lazy" alt="隠遁者 ルディ CV：三木眞一郎"></h2>
          <picture class="character-visual">
            <source media="(min-width: 829px)" srcset="@/assets/img/character-rudy-visual-pc.png">
            <img src="@/assets/img/character-rudy-visual-sp.png" loading="lazy" alt="ルディ">
          </picture>
        </div>
        <div class="character-detail-right">
          <img class="character-text" src="@/assets/img/character-rudy-text.png" loading="lazy" alt="「帰れ。私はもう光の騎士などではない。」">
          <p>その昔、信念のためには<br>
            いかなる犠牲も払うべきだと固く信じていた光の騎士だったが、<br>
            降臨の日以降、自分のせいで世界が破滅したという<br>
            罪悪感で人生を投げ出してしまった。<br>
            酒を飲みながら放浪する廃人になってカルニアに流れ着いた後は、<br>
            聖女会のヘンリーが製造する麻薬に毒され、<br>
            地下にある秘密闘技場の喧嘩屋へ身に転落してしまう。</p>
          <ul class="character-detail-img">
            <li><img src="@/assets/img/character-rudy-thumbnail01.jpg" loading="lazy" alt="ルディ2D" @click="showRudy2d = true"></li>
            <li><img src="@/assets/img/character-rudy-thumbnail02.jpg" loading="lazy" alt="ルディ3D" @click="showRudy3d = true"></li>
            <li><iframe class="movie-iframe" src="//www.youtube.com/embed/Ns5HiaHfaXY?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
          </ul>
        </div>
      </div>
      <button class="modal-prev" @click="showRudy = false; showChengchen = true; stopVideo()"><img src="@/assets/img/icon-prev.png" loading="lazy" alt="prev"></button>
      <button class="modal-next" @click="showRudy = false; showEileene = true; stopVideo()"><img src="@/assets/img/icon-next.png" loading="lazy" alt="next"></button>
      <button class="modal-close" @click="closeCharacter"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background"></div>
    </div>
    <div class="character-detail-visual modal" v-show="showRudy2d">
      <img src="@/assets/img/character-rudy-visual-2d.jpg" loading="lazy" alt="ルディ">
      <button class="modal-close" @click="showRudy2d = false"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background" @click="showRudy2d = false"></div>
    </div>
    <div class="character-detail-visual modal" v-show="showRudy3d">
      <img src="@/assets/img/character-rudy-visual-3d.jpg" loading="lazy" alt="ルディ">
      <button class="modal-close" @click="showRudy3d = false"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background" @click="showRudy3d = false"></div>
    </div>
    <div class="character-eileene character-detail modal" v-show="showEileene">
      <img class="character-vertical" src="@/assets/img/character-title-vertical.png" loading="lazy" alt="Character">
      <div class="modal-scroll">
        <div class="character-detail-left">
          <h2><img src="@/assets/img/character-eileene-title.png" loading="lazy" alt="フォーディナの女帝 アイリーン CV：勝生真沙子"></h2>
          <picture class="character-visual">
            <source media="(min-width: 829px)" srcset="@/assets/img/character-eileene-visual-pc.png">
            <img src="@/assets/img/character-eileene-visual-sp.png" loading="lazy" alt="アイリーン">
          </picture>
        </div>
        <div class="character-detail-right">
          <img class="character-text" src="@/assets/img/character-eileene-text.png" loading="lazy" alt="「女帝の前に跪け！」">
          <p>セブンナイツとして、世界を救う旅に参加していた英雄。<br>
            新たに黎明の傭兵団を築き上げ、レンを強く育てようと努力するが、<br>
            レンにそんな気持ちを分かってもらえず、アイリーンも<br>
            自身の想いをすべて打ち明けることができずに切なく思っている。</p>
          <ul class="character-detail-img">
            <li><img src="@/assets/img/character-eileene-thumbnail01.jpg" loading="lazy" alt="アイリーン2D" @click="showEileene2d = true"></li>
            <li><img src="@/assets/img/character-eileene-thumbnail02.jpg" loading="lazy" alt="アイリーン3D" @click="showEileene3d = true"></li>
            <li><iframe class="movie-iframe" src="//www.youtube.com/embed/6kkVxsZyeKk?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></li>
          </ul>
        </div>
      </div>
      <button class="modal-prev" @click="showEileene = false; showRudy = true; stopVideo()"><img src="@/assets/img/icon-prev.png" loading="lazy" alt="prev"></button>
      <button class="modal-next" @click="showEileene = false; showLene = true; stopVideo()"><img src="@/assets/img/icon-next.png" loading="lazy" alt="next"></button>
      <button class="modal-close" @click="closeCharacter"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background"></div>
    </div>
    <div class="character-detail-visual modal" v-show="showEileene2d">
      <img src="@/assets/img/character-eileene-visual-2d.jpg" loading="lazy" alt="アイリーン">
      <button class="modal-close" @click="showEileene2d = false"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background" @click="showEileene2d = false"></div>
    </div>
    <div class="character-detail-visual modal" v-show="showEileene3d">
      <img src="@/assets/img/character-eileene-visual-3d.jpg" loading="lazy" alt="アイリーン">
      <button class="modal-close" @click="showEileene3d = false"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
      <div class="modal-background" @click="showEileene3d = false"></div>
    </div>
    <section id="world" class="fadeIn">
      <h1><img src="@/assets/img/world-title-sp.png" loading="lazy" alt="ワールド"></h1>
      <div class="contents">
        <iframe class="youtube movie-iframe" src="//www.youtube.com/embed/LgtvUBNoG3g?enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <!-- <YouTube class="youtube" src="//www.youtube.com/watch?v=LgtvUBNoG3g" ref="youtube" :player-vars="playerVars"/> -->
        <img class="world-text" src="@/assets/img/world-text.png" loading="lazy" alt="美しいグラフィックで表現されるセブンナイツの世界">
      </div>
    </section>
    <div class="background" v-if="isMobile() == 'ios'">
      <img class="video" src="@/assets/video/sparks.gif" loading="lazy" alt="">
    </div>
    <div class="background" v-else-if="isMobile() == 'android'">
      <video src="@/assets/video/sparks.mp4" playsinline muted autoplay loop></video>
    </div>
    <div class="background" v-else>
      <video src="@/assets/video/sparks.mp4" playsinline muted autoplay loop></video>
    </div>
  </div>
  <Sticky/>
</template>

<style lang="scss">
.world {
  background: #191818;
  color: #e6e5e2;
  font-size: 2.4rem;
  padding: 28rem 0 13.6rem;
  @media (min-width: 829px) {
    font-size: 1.4rem;
    padding: 17.9rem 0 9.7rem;
  }
  h1 {
    margin: 0 auto;
  }
  .modal-close {
    position: absolute;
    right: 3.6rem;
    top: 3.3rem;
    width: 9.5rem;
    @media (min-width: 829px) {
      height: 3.9rem;
      right: 4.1rem;
      top: 4.8rem;
      width: 6.3rem;
    }
  }
}

/* prologue */

#prologue h1 {
  width: 26.1rem;
  @media (min-width: 829px) {
    width: 20rem;
  }
}

.prologue-text {
  line-height: 3.75;
  margin-top: 6.2rem;
  text-align: center;
  @media (min-width: 829px) {
    font-size: 1.8rem;
    line-height: 3.778;
  }
}

/* character */

#character {
  margin-top: 17.3rem;
  @media (min-width: 829px) {
    margin-top: 13.5rem;
  }
  h1 {
    width: 34.2rem;
    @media (min-width: 829px) {
      width: 26rem;
    }
  }
}

.character-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10.3rem;
  justify-content: center;
  @media (min-width: 829px) {
    margin-top: 3.9rem;
  }
  li {
    a {
      clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0 25%);
      display: block;
      margin: 0 auto;
      width: 18.8rem;
      @media (min-width: 829px) {
        width: 12.5rem;
      }
    }
    ~ li {
      @media (min-width: 829px) {
        margin-left: 2rem !important;
      }
    }
    &:nth-child(2),
    &:nth-child(5) {
      margin-left: 3.7rem;
    }
    &:nth-child(2) ~ li {
      margin-top: -5.3rem;
      @media (min-width: 829px) {
        margin-top: 0;
      }
    }
    &:nth-child(3),
    &:nth-child(6) {
      width: 100%;
      @media (min-width: 829px) {
        width: auto;
      }
    }
  }
}

.character-detail::before,
.character-vertical,
.character-detail h2,
.character-visual,
.character-text {
  display: block;
  left: 0;
  position: absolute;
  top: 0;
}

.character-detail {
  display: block;
  &::before {
    content: '';
    height: 100%;
    width: 100%;
  }
  h2 {
    margin: 0 auto;
    right: 0;
  }
  .modal-background {
    background: #191818;
  }
}

.character-detail-left {
  @media (min-width: 829px) {
    left: calc(50% - 53rem);
    position: absolute;
    top: 0;
    width: 53rem;
  }
}

.character-detail-right {
  padding-bottom: 10rem;
  @media (min-width: 829px) {
    margin-left: 50%;
    padding-top: 18.9rem;
    position: relative;
    width: 49.5rem;
  }
}

.character-vertical {
  width: 5.6rem;
  @media (min-width: 829px) {
    left: calc(50% - 49.7rem);
    width: 4.1rem;
  }
}

.character-text {
  margin: 0 auto;
  right: 0;
  + p {
    line-height: 2.75;
    margin-top: 141.3rem;
    text-align: center;
    @media (min-width: 829px) {
      line-height: 3.286;
      margin-top: 0;
    }
  }
}

.character-detail-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 7rem auto 0;
  position: relative;
  width: 60.1rem;
  @media (min-width: 829px) {
    margin-top: 6.2rem;
    width: 42.1rem;
  }
  &::before {
    background: url('~@/assets/img/line.png') no-repeat 0 0 / 100% 100%;
    content: '';
    display: block;
    height: .8rem;
    left: calc(50% - 39.35rem);
    position: absolute;
    top: -5rem;
    width: 78.7rem;
    @media (min-width: 829px) {
      height: .7rem;
      left: -3.9rem;
      top: -3.7rem;
      width: 49.8rem;
    }
  }
  li {
    border: 1px solid #73674f;
    width: 28.9rem;
    @media (min-width: 829px) {
      width: 20.4rem;
    }
    img {
      cursor: pointer;
    }
    &:last-child {
      margin-top: 2rem;
      width: 100%;
      @media (min-width: 829px) {
        margin-top: 1.4rem;
      }
      iframe {
        height: 33.9rem;
        width: 100%;
        @media (min-width: 829px) {
          height: 23.7rem;
        }
      }
    }
  }
}

.character-detail-visual > img {
  @media (max-width: 828px) and (orientation: landscape) {
    height: 100%;
    width: auto;
  }
  @media (min-width: 829px) {
    height: 80%;
    width: auto;
  }
}

.character-lene {
  &::before {
    background: url('~@/assets/img/character-lene-background-sp.jpg') no-repeat 0 0 / 100%;
    @media (min-width: 829px) {
      background: url('~@/assets/img/character-lene-background-pc.jpg') no-repeat calc(50% - 22.2rem) 0 / 85.8rem;
    }
  }
  h2 {
    top: 15.4rem;
    width: 23.8rem;
    @media (min-width: 829px) {
      top: 10.1rem;
      width: 16.8rem;
    }
  }
  .character-visual {
    @media (min-width: 829px) {
      left: -5.3rem;
      width: 60.1rem;
    }
  }
  .character-text {
    top: 126.4rem;
    width: 64.3rem;
    @media (min-width: 829px) {
      top: 8.2rem;
      width: 42.8rem;
    }
  }
}

.character-lukey {
  &::before {
    background: url('~@/assets/img/character-lukey-background-sp.jpg') no-repeat 0 0 / 100%;
    @media (min-width: 829px) {
      background: url('~@/assets/img/character-lukey-background-pc.jpg') no-repeat calc(50% - 22.2rem) 0 / 57.2rem;
    }
  }
  h2 {
    top: 15.4rem;
    width: 33.2rem;
    @media (min-width: 829px) {
      top: 10.1rem;
      width: 23.6rem;
    }
  }
  .character-visual {
    @media (min-width: 829px) {
      left: -5.5rem;
      width: 53.6rem;
    }
  }
  .character-text {
    top: 126.4rem;
    width: 59rem;
    @media (min-width: 829px) {
      top: 8.2rem;
      width: 42rem;
    }
  }
}

.character-kade {
  &::before {
    background: url('~@/assets/img/character-kade-background-sp.jpg') no-repeat 0 0 / 100%;
    @media (min-width: 829px) {
      background: url('~@/assets/img/character-kade-background-pc.jpg') no-repeat calc(50% - 28.7rem) 0 / 69.4rem;
    }
  }
  h2 {
    top: 15.4rem;
    width: 24.2rem;
    @media (min-width: 829px) {
      position: relative;
      top: 10.1rem;
      width: 17.6rem;
      z-index: 1;
    }
  }
  .character-visual {
    @media (min-width: 829px) {
      left: -5.6rem;
      width: 56.4rem;
    }
  }
  .character-text {
    top: 126.4rem;
    width: 62.4rem;
    @media (min-width: 829px) {
      top: 8.2rem;
      width: 42.2rem;
    }
  }
}

.character-chengchen {
  &::before {
    background: url('~@/assets/img/character-chengchen-background-sp.jpg') no-repeat 0 0 / 100%;
    @media (min-width: 829px) {
      background: url('~@/assets/img/character-chengchen-background-pc.jpg') no-repeat calc(50% - 28.2rem) 0 / 62.6rem;
    }
  }
  h2 {
    top: 15.4rem;
    width: 46rem;
    @media (min-width: 829px) {
      top: 10.1rem;
      width: 31.8rem;
    }
  }
  .character-visual {
    @media (min-width: 829px) {
      left: -5.6rem;
      width: 57rem;
    }
  }
  .character-text {
    top: 126.4rem;
    width: 62.4rem;
    @media (min-width: 829px) {
      top: 8.2rem;
      width: 41.6rem;
    }
  }
}

.character-rudy {
  &::before {
    background: url('~@/assets/img/character-rudy-background-sp.jpg') no-repeat 0 0 / 100%;
    @media (min-width: 829px) {
      background: url('~@/assets/img/character-rudy-background-pc.jpg') no-repeat calc(50% - 7.2rem) 0 / 105.4rem;
    }
  }
  h2 {
    top: 15.4rem;
    width: 24rem;
    @media (min-width: 829px) {
      top: 10.1rem;
      width: 17.2rem;
    }
  }
  .character-visual {
    @media (min-width: 829px) {
      left: -2.7rem;
      width: 57.8rem;
    }
  }
  .character-text {
    top: 126.4rem;
    width: 64.2rem;
    @media (min-width: 829px) {
      top: 8.2rem;
      width: 43.4rem;
    }
  }
}

.character-eileene {
  &::before {
    background: url('~@/assets/img/character-eileene-background-sp.jpg') no-repeat 0 0 / 100%;
    @media (min-width: 829px) {
      background: url('~@/assets/img/character-eileene-background-pc.jpg') no-repeat calc(50% - 25.2rem) 0 / 61.6rem;
    }
  }
  h2 {
    top: 15.4rem;
    width: 39.2rem;
    @media (min-width: 829px) {
      top: 10.1rem;
      width: 27.6rem;
    }
  }
  .character-visual {
    @media (min-width: 829px) {
      left: -6rem;
      width: 58.4rem;
    }
  }
  .character-text {
    top: 126.4rem;
    width: 49.4rem;
    @media (min-width: 829px) {
      top: 8.2rem;
      width: 33.2rem;
    }
  }
}

/* world */

#world {
  margin-top: 20.3rem;
  @media (min-width: 829px) {
    margin-top: 13.5rem;
  }
  h1 {
    width: 22.8rem;
    @media (min-width: 829px) {
      width: 17.3rem;
    }
  }
  .contents {
    margin-top: 6rem;
    @media (min-width: 829px) {
      margin: 4rem auto 0;
      width: 45.6rem;
    }
  }
}

.world-img {
  margin: 4.6rem auto 0;
  width: 73.9rem;
  @media (min-width: 829px) {
    margin-top: 6rem;
    width: 100%;
  }
}

.world-text {
  width: 65.2rem;
  margin: 3.6rem auto 0;
  @media (min-width: 829px) {
    width: 36.5rem;
    margin-top: 2.3rem;
  }
}

</style>

<script>
import YouTube from 'vue3-youtube'
import Sticky from '@/components/Sticky.vue'

const userAgent = navigator.userAgent

export default {
  components: {
    YouTube,
    Sticky
  },
  data() {
    return {
      scrollY: 0,
      showLene: false,
      showLene2d: false,
      showLene3d: false,
      showLukey: false,
      showLukey2d: false,
      showLukey3d: false,
      showKade: false,
      showKade2d: false,
      showKade3d: false,
      showChengchen: false,
      showChengchen2d: false,
      showChengchen3d: false,
      showRudy: false,
      showRudy2d: false,
      showRudy3d: false,
      showEileene: false,
      showEileene2d: false,
      showEileene3d: false,
      playerVars: {
        playsinline: 1
      }
    }
  },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
        if (userAgent.match(/iPhone/i) || userAgent.match(/iPad/i) || userAgent.match(/iPod/i)) {
          return 'ios'
        } else {
          return 'android'
        }
      } else {
        return false
      }
    },
    calculateScrollY() {
      scrollY = window.scrollY
    },
    scrollFixed() {
      const body = document.body
      this.calculateScrollY()
      body.style.top = -scrollY + 'px'
      body.classList.add('is-fixed')
    },
    scrollActive() {
      const body = document.body
      body.style.top = 0
      body.classList.remove('is-fixed')
      document.documentElement.scrollTop = scrollY
    },
    openLene(e) {
      e.preventDefault()
      this.stopVideo()
      this.scrollFixed()
      this.showLene = true
    },
    openLukey(e) {
      e.preventDefault()
      this.stopVideo()
      this.scrollFixed()
      this.showLukey = true
    },
    openKade(e) {
      e.preventDefault()
      this.stopVideo()
      this.scrollFixed()
      this.showKade = true
    },
    openChengchen(e) {
      e.preventDefault()
      this.stopVideo()
      this.scrollFixed()
      this.showChengchen = true
    },
    openRudy(e) {
      e.preventDefault()
      this.stopVideo()
      this.scrollFixed()
      this.showRudy = true
    },
    openEileene(e) {
      e.preventDefault()
      this.stopVideo()
      this.scrollFixed()
      this.showEileene = true
    },
    closeCharacter() {
      this.stopVideo()
      this.scrollActive()
      this.showLene = false
      this.showLukey = false
      this.showKade = false
      this.showChengchen = false
      this.showRudy = false
      this.showEileene = false
    },
    async fetchYoutube() {
      await (this.isOpen = true);
      this.$refs.youtube.fetchData();
    },
    onReady() {
      const player = this.$refs.youtube.player
      player.mute()
      player.playVideo()
    },
    stopVideo() {
      const iframe = document.getElementsByClassName('movie-iframe')
      const iframeDocument0 = iframe[0].contentWindow
      const iframeDocument1 = iframe[1].contentWindow
      const iframeDocument2 = iframe[2].contentWindow
      const iframeDocument3 = iframe[3].contentWindow
      const iframeDocument4 = iframe[4].contentWindow
      const iframeDocument5 = iframe[5].contentWindow
      const iframeDocument6 = iframe[6].contentWindow
      iframeDocument0.postMessage('{"event":"command","func":"'+"stopVideo"+'","args":""}', '*')
      iframeDocument1.postMessage('{"event":"command","func":"'+"stopVideo"+'","args":""}', '*')
      iframeDocument2.postMessage('{"event":"command","func":"'+"stopVideo"+'","args":""}', '*')
      iframeDocument3.postMessage('{"event":"command","func":"'+"stopVideo"+'","args":""}', '*')
      iframeDocument4.postMessage('{"event":"command","func":"'+"stopVideo"+'","args":""}', '*')
      iframeDocument5.postMessage('{"event":"command","func":"'+"stopVideo"+'","args":""}', '*')
      iframeDocument6.postMessage('{"event":"command","func":"'+"pauseVideo"+'","args":""}', '*')
    }
  }
}
</script>
