<template>
  <div class="home">
    <section id="hero">
      <h1 class="hero-logo"><img src="@/assets/img/logo-sk2.png" loading="lazy" alt="セブンナイツ2"></h1>
      <img class="hero-copywriting" src="@/assets/img/hero-copywriting.png" loading="lazy" alt="英雄を集結して絶望に立ち向かえ──">
      <img class="hero-text" src="@/assets/img/hero-text.png" loading="lazy" alt="2021年サービス開始予定">
      <a class="hero-button" href="#preregistration" @click="returnPreregistration"><img src="@/assets/img/button-click.png" loading="lazy" alt="事前登録はこちらから"></a>
      <a class="hero-movie fadeIn" href="#" @click="openMovie"><img src="@/assets/img/hero-movie.jpg" loading="lazy" alt="セブンナイツ2 スペシャルムービー"></a>
      <div class="modal" v-show="showMovie">
        <div class="movie">
          <YouTube src="//www.youtube.com/watch?v=y-dh13fwMRc?enablejsapi=1" ref="youtube" :player-vars="playerVars"/>
          <button class="modal-close" @click="closeMovie"><img src="@/assets/img/nav-close.png" loading="lazy" alt="閉じる"></button>
        </div>
        <div class="modal-background"></div>
      </div>
    </section>
    <transition name="fade">
      <div class="home-background" v-show="homeBackground">
        <video src="@/assets/video/home-background.mp4" playsinline muted autoplay loop></video>
      </div>
    </transition>
    <section id="news" class="fadeIn">
      <carousel :settings="settings" :breakpoints="breakpoints">
        <slide :key="i" v-for="(banner,i) in banners"><div class="carousel__item"><a :href="banner.burl"><img :src="banner.bimg" loading="lazy" alt=""></a></div></slide>
        <template #addons>
          <navigation/>
          <pagination/>
        </template>
      </carousel>
    </section>
    <section id="preregistration" class="box fadeIn">
      <h2 class="box-title">
        <picture>
          <source media="(min-width: 829px)" srcset="@/assets/img/preregistration-title-pc.png">
          <img src="@/assets/img/preregistration-title-sp.png" loading="lazy" alt="事前登録">
        </picture>
      </h2>
      <div class="box-wrap">
        <div class="box-left">
          <p class="box-text">現在事前登録受付中！<br>
            登録するとゲーム内で使用できる<span class="yellow">限定ペット</span>や<span class="yellow">限定装備</span>、<br>
            <span class="yellow">15万ゴールド(ゲーム内通貨)</span>をプレゼント！<br>
            ※限定装備につきましては、2種類のうちどちらか1つを<br>
            受け取ることができます。</p>
          <p class="box-label"><span>期間</span>2021年10月7日(木)〜配信開始まで</p>
          <picture>
            <source media="(min-width: 829px)" srcset="@/assets/img/preregistration-item-pc.png">
            <img class="preregistration-item" src="@/assets/img/preregistration-item-sp.png" loading="lazy" alt="">
          </picture>
        </div>
        <div class="box-right">
          <ul class="preregistration-list" v-if="isMobile() == 'ios'">
            <li><img src="@/assets/img/badge-appstore.png" loading="lazy" alt="App Storeで予約注文" @click="appleBtnClick"></li>
            <li><img src="@/assets/img/badge-twitter.png" loading="lazy" alt="Twitterで事前登録" @click="twitterBtnClick"></li>
            <li><img src="@/assets/img/badge-line.png" loading="lazy" alt="LINEで事前登録" @click="lineBtnClick"></li>
          </ul>
          <ul class="preregistration-list" v-else-if="isMobile() == 'android'">
            <li><img src="@/assets/img/badge-googleplay.png" loading="lazy" alt="Google Playでの事前登録" @click="googleBtnClick"></li>
            <li><img src="@/assets/img/badge-twitter.png" loading="lazy" alt="Twitterで事前登録" @click="twitterBtnClick"></li>
            <li><img src="@/assets/img/badge-line.png" loading="lazy" alt="LINEで事前登録" @click="lineBtnClick"></li>
          </ul>
          <ul class="preregistration-list" v-else>
            <li><img src="@/assets/img/badge-googleplay.png" loading="lazy" alt="Google Playでの事前登録" @click="googleBtnClick"></li>
            <li><img src="@/assets/img/badge-appstore.png" loading="lazy" alt="App Storeで予約注文" @click="appleBtnClick"></li>
            <li><img src="@/assets/img/badge-twitter.png" loading="lazy" alt="Twitterで事前登録" @click="twitterBtnClick"></li>
            <li><img src="@/assets/img/badge-line.png" loading="lazy" alt="LINEで事前登録" @click="lineBtnClick"></li>
          </ul>
          <div class="preregistration-mail">
            <p class="box-text">メールアドレスからもご登録いただけます。</p>
            <div class="box-input">
              <input v-model="registMail" :class="{'is-active': !inputActive}">
              <p class="box-input-placeholder">メールアドレスを入力してください。</p>
              <p class="box-input-error" v-show="validEmailError">{{errorMessage}}</p>
            </div>
            <div class="box-check">
              <label><input type="checkbox" v-model="agreechecked"><span class="box-checkbox"></span>事前登録に関する注意事項に同意する</label>
              <button class="box-button" v-on:click="openConfirm"><img src="@/assets/img/badge-mail.png" loading="lazy" alt="メールアドレスで事前登録"></button>
            </div>
          </div>
        </div>
      </div>
      <iframe :src="iframeSrc" width="0" height="0" frameborder="0"></iframe>
    </section>
    <div class="preregistration-confirm modal" v-show="showContent1">
      <div class="modal-scroll">
        <div class="box">
          <h3><img src="@/assets/img/preregistration-confirm.png" loading="lazy" alt="メールアドレスのご確認"></h3>
          <p class="box-text">登録するメールアドレスはこちらでよろしいでしょうか？</p>
          <p class="preregistration-confirm-mail">{{registMail}}</p>
          <button class="preregistration-now"><img src="@/assets/img/button-now.png" @click="prereg" loading="lazy" alt="事前登録する"></button>
        </div>
        <button class="modal-close"><img src="@/assets/img/button-correct.png" loading="lazy" alt="修正する" @click="showContent1=false"></button>
      </div>
      <div class="modal-background"></div>
    </div>
    <div class="preregistration-invalid modal" v-show="showContent2">
      <div>
        <div class="box">
          <h3><img src="@/assets/img/preregistration-invalid.png" loading="lazy" alt="無効なメールアドレスです。"></h3>
          <p class="box-text">ご入力いただいたメールアドレスは<span class="br"></span>既に登録済みのメールアドレスです。</p>
        </div>
        <button class="modal-close"><img src="@/assets/img/button-correct.png" loading="lazy" @click="this.showContent2 = false" alt="修正する"></button>
      </div>
      <div class="modal-background"></div>
    </div>
    <div class="preregistration-complete modal" v-show="showContent3">
      <div>
        <div class="box">
          <h3><img src="@/assets/img/preregistration-complete.png" loading="lazy" alt="事前登録が完了しました。"></h3>
          <p class="box-text">事前登録にお申し込みいただきありがとうございました。<br>
          登録完了メールをお送りさせていただきます。<br>
          正式リリースまで今しばらくお待ちください。</p>
        </div>
        <button class="modal-close"><img src="@/assets/img/button-close.png" loading="lazy" @click="this.showContent3 = false" alt="閉じる"></button>
      </div>
      <div class="modal-background"></div>
    </div>
    <div class="section-wrap">
      <section id="twitter" class="section-left fadeIn">
        <div class="box">
          <h2 class="box-title">
            <picture>
              <source media="(min-width: 829px)" srcset="@/assets/img/twitter-title-pc.png">
              <img src="@/assets/img/twitter-title-sp.png" loading="lazy" alt="Twitter">
            </picture>
          </h2>
          <div class="twitter-box">
            <a class="twitter-timeline" data-theme="dark" data-chrome="noheader nofooter transparent" href="//twitter.com/Sena2_JP?ref_src=twsrc%5Etfw" target="_blank" rel="noopener">Tweets by Sena2_JP</a>
          </div>
        </div>
        <a class="twitter-button" href="//twitter.com/Sena2_JP" target="_blank" rel="noopener">
          <picture>
            <source media="(min-width: 829px)" srcset="@/assets/img/twitter-button-pc.png">
            <img src="@/assets/img/twitter-button-sp.png" loading="lazy" alt="公式Twitter">
          </picture>
        </a>
      </section>
      <section id="campaign" class="section-right box fadeIn">
        <h2 class="box-title">
          <picture>
            <source media="(min-width: 829px)" srcset="@/assets/img/campaign-title-pc.png">
            <img src="@/assets/img/campaign-title-sp.png" loading="lazy" alt="キャンペーン">
          </picture>
        </h2>
        <ul class="contents">
          <li><a href="https://sevenknights2.netmarble.jp/event/checkup2021/"><img class="present-img" src="@/assets/img/campaign-banner03.jpg" loading="lazy" alt="英雄診断"></a></li>
          <li><a href="https://sevenknights2.netmarble.jp/event/preregcp/"><img class="present-img" src="@/assets/img/campaign-banner02.jpg" loading="lazy" alt="ギガンテス討伐戦"></a></li>
          <li><router-link to="/preplay/"><img class="present-img" src="@/assets/img/campaign-banner01.jpg" loading="lazy" alt="先行体験傭兵団員募集!"></router-link></li>
          <!-- <li><img class="present-img" src="@/assets/img/campaign-banner-comingsoon.jpg" loading="lazy" alt="Coming Soon"></li> -->
        </ul>
      </section>
    </div>
    <section id="notes" class="box fadeIn">
      <h2 class="box-title">
        <picture>
          <source media="(min-width: 829px)" srcset="@/assets/img/notes-title-pc.png">
          <img src="@/assets/img/notes-title-sp.png" loading="lazy" alt="事前登録に関する注意事項">
        </picture>
      </h2>
      <ul class="box-list fadeIn">
        <li>各報酬は達成状況に応じて、正式サービス開始後にゲーム内のメールにて配布させて頂きます。</li>
        <li>事前登録報酬の限定装備は、事前登録方法によって2種類のうちどちらか1つを受け取ることができます。詳細に関しましては後日お知らせします。</li>
        <li>事前登録および正式リリースのご案内は、登録いただいたメールアドレス宛にお送りいたします。登録するメールアドレスはお間違いのないようにご注意ください。</li>
        <li>個人情報の取り扱いに関しては、プライバシーポリシーをご確認ください。</li>
        <li>受信拒否設定を行っている場合は、設定を解除するか「no-reply@netmarble.jp」からのメールを受け取れるように設定をお願いいたします。</li>
        <li>AppStoreへ遷移後、[入手]をタップしていただく事で、事前登録が完了します。</li>
        <li>Google Playへ遷移後、[事前登録]をタップしていただく事で、事前登録が完了します。</li>
        <li>公式LINEアカウントを友だち登録していただく事で、事前登録が完了します。</li>
        <li>公式Twitter(@Sena2_JP)アカウントをフォローしていただく事で、事前登録が完了します。</li>
        <li>本キャンペーンの報酬内容は予告なく変更になる場合がございます。</li>
      </ul>
    </section>
  </div>
</template>

<style lang="scss">
.home {
  padding-bottom: 6rem;
}

.home-background {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
  &::before {
    background: linear-gradient(to left, #44413a, #2b2a29);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    mix-blend-mode: screen;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  video {
    height: 100vh;
    left: calc(50% - 89vh);
    opacity: .2;
    position: absolute;
    top: 0;
    width: 178vh;
    @media (min-width: 829px) {
      transform: scale(1.5);
    }
  }
}

/* box */

.box {
  background: rgba(20, 20, 20, .8);
  border: 1px solid #73674f;
  margin: 6rem auto 0;
  padding: 4.1rem 0 6.1rem;
  width: 74rem;
  @media (min-width: 829px) {
    width: 103.8rem;
  }
}

.box-wrap {
  @media (min-width: 829px) {
    display: flex;
    justify-content: center;
  }
}

.box-left,
.box-right {
  @media (min-width: 829px) {
    width: 50%;
  }
}

.box-text {
  color: #cfdad3;
  line-height: 1.818;
  text-align: center;
  @media (min-width: 829px) {
    line-height: 2;
  }
  .box-title + &,
  .box-title + .box-wrap .box-left &:nth-child(1) {
    margin-top: 2.9rem;
    @media (min-width: 829px) {
      margin-top: 3.6rem;
    }
  }
  .yellow {
    color: #ffde00;
  }
}

.box-label {
  background: #2f2e2b;
  clip-path: polygon(2.874% 0, 97.126% 0, 100% 50%, 97.126% 100%, 2.874% 100%, 0 50%);
  color: #cfdad3;
  height: 4rem;
  line-height: 4rem;
  margin: 3.1rem auto 0;
  text-align: center;
  width: 52.2rem;
  @media (min-width: 829px) {
    height: 3rem;
    line-height: 3rem;
    margin-top: 2.2rem;
    width: 37.8rem;
  }
  span {
    margin-right: 1.3rem;
    padding-right: 1.6rem;
    position: relative;
    @media (min-width: 829px) {
      margin-right: 2.5rem;
      padding-right: 1.4rem;
    }
    &::before {
      background: #616161;
      content: '';
      height: 3.2rem;
      position: absolute;
      right: 0;
      top: .2rem;
      width: 1px;
      @media (min-width: 829px) {
        height: 2.6rem;
        top: -.3rem;
      }
    }
  }
}

.box-input {
  margin: 1.5rem auto 0;
  position: relative;
  width: 62rem;
  @media (min-width: 829px) {
    margin-top: .6rem;
    width: 44.4rem;
  }
  input {
    background: #8e8e8e;
    border: 1px solid #616161;
    color: #212020;
    height: 10.6rem;
    line-height: 10.6rem;
    text-align: center;
    width: 100%;
    @media (min-width: 829px) {
      height: 4.8rem;
      line-height: 4.8rem;
    }
    &:focus ~ .box-input-placeholder,
    &.is-active ~ .box-input-placeholder {
      opacity: 0;
      transform: translate3d(1rem, 0, 0);
    }
  }
}

.box-input-placeholder,
.box-input-error {
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
}

.box-input-placeholder {
  color: #212020;
  height: 1em;
  pointer-events: none;
  top: 3.6rem;
  transition: transform .3s, opacity .3s;
  @media (min-width: 829px) {
    top: 1.2rem;
  }
}

.box-input-error {
  color: #c91818;
  top: 13rem;
  @media (min-width: 829px) {
    top: 7.2rem;
  }
}

.box-check {
  color: #cfdad3;
  text-align: center;
  [type="checkbox"] {
    display: none;
    &:checked + .box-checkbox::before {
      opacity: 1;
    }
  }
}

.box-checkbox {
  background: #cfdad3;
  border: 1px solid #777;
  border-radius: .4rem;
  display: inline-block;
  height: 2.8rem;
  margin-right: 2rem;
  position: relative;
  top: .5rem;
  width: 2.8rem;
  @media (min-width: 829px) {
    height: 1.9rem;
    margin-right: 1.3rem;
    width: 1.9rem;
  }
  &::before {
    background: url('~@/assets/img/icon-check.png') no-repeat 0 0 / 100% 100%;
    content: '';
    display: block;
    height: 2.6rem;
    left: .3rem;
    opacity: 0;
    position: absolute;
    top: -.3rem;
    width: 2.6rem;
    @media (min-width: 829px) {
      height: 1.7rem;
      left: .2rem;
      top: -.2rem;
      width: 1.8rem;
    }
  }
}

.box-button {
  background: none;
  border: 0;
  display: block;
  margin: 3.8rem auto 0;
  width: 44.2rem;
  @media (min-width: 829px) {
    margin-top: 2.2rem;
    width: 21.8rem;
  }
}

/* hero */

#hero {
  height: 187.7rem;
  overflow: hidden;
  position: relative;
  @media (min-width: 829px) {
    height: 108rem;
  }
  &::before {
    background: url('~@/assets/img/hero-visual-sp.png') no-repeat 0 0 / 100%;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(1.05, 1.05);
    transform-origin: 50% 0;
    width: 100%;
    @media (min-width: 829px) {
      background: url('~@/assets/img/hero-visual-pc.jpg') no-repeat 0 0 / 192rem;
    }
  }
  &.is-active::before {
    animation: heroVisual 5s linear forwards;
  }
}

@keyframes heroVisual {
  0% {
    transform: scale(1.05, 1.05);
  }
  100% {
    transform: scale(1, 1);
  }
}

.hero-logo,
.hero-copywriting,
.hero-text,
.hero-button,
.hero-movie {
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
}

.hero-logo,
.hero-copywriting,
.hero-text,
.hero-button {
  opacity: 0;
}

.hero-logo {
  top: 77.7rem;
  width: 59.4rem;
  @media (min-width: 829px) {
    left: calc(50% + 16.5rem);
    top: 15.6rem;
    width: 43.5rem;
  }
  .is-active & {
    animation: display 1s forwards;
    animation-delay: 1s;
  }
}

@keyframes display {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hero-copywriting {
  left: .6rem;
  margin: 0;
  top: 11.7rem;
  width: 7.5rem;
  @media (min-width: 829px) {
    left: 6.9rem;
    top: 7.3rem;
    width: 6.7rem;
  }
  .is-active & {
    animation: display 1s forwards;
    animation-delay: 2s;
  }
}

.hero-text {
  top: 116.7rem;
  width: 54.7rem;
  @media (min-width: 829px) {
    left: calc(50% + 16.5rem);
    top: 43.9rem;
    width: 43.4rem;
  }
  .is-active & {
    animation: display 1s forwards;
    animation-delay: 2s;
  }
}

.hero-button {
  top: 125.9rem;
  width: 57.8rem;
  @media (min-width: 829px) {
    left: calc(50% + 16rem);
    top: 52.2rem;
    width: 41rem;
  }
  .is-active & {
    animation: display 1s forwards;
    animation-delay: 2s;
  }
}

.hero-movie {
  display: block;
  top: 147.8rem;
  width: 60.3rem;
  @media (min-width: 829px) {
    left: calc(50% + 16.6rem);
    top: 67.9rem;
    width: 40.3rem;
  }
}

.movie,
.movie iframe {
  height: 56vw !important;
  width: 100vw !important;
  @media (max-width: 828px) and (orientation: landscape) {
    height: 56vh !important;
    width: 100vh !important;
  }
  @media (min-width: 829px) {
    height: 56.3rem !important;
    width: 100rem !important;
  }
}

.movie .modal-close {
  position: absolute;
  right: 3.6rem;
  top: 3.3rem;
  width: 9.5rem;
  @media (min-width: 829px) {
    height: 3.9rem;
    right: 4.1rem;
    top: 4.8rem;
    width: 6.3rem;
  }
}

/* slider */

.carousel {
  margin-top: 4rem;
  @media (min-width: 829px) {
    margin: 4.9rem auto 0;
    width: 100rem;
  }
}

.carousel__track {
  @media (min-width: 829px) {
    // justify-content: center;
  }
}

.carousel__slide {
  margin: 0 auto;
  padding: 0 4.4rem;
  @media (min-width: 829px) {
    margin: 0;
    padding: 0;
    width: 32rem !important;
  }
  ~ .carousel__slide {
    @media (min-width: 829px) {
      margin-left: 2rem;
    }
  }
}

.carousel__prev,
.carousel__next {
  bottom: 0;
  height: 9.1rem;
  margin: auto 0;
  position: absolute;
  top: 0;
  transform: translate(0, 0);
  width: 7rem;
  @media (min-width: 829px) {
    height: 6.3rem;
    width: 5rem;
  }
  svg {
    display: none;
  }
}

.carousel__prev {
  background: url('~@/assets/img/icon-prev.png') no-repeat 0 0 / 100% 100%;
  left: 0;
  @media (min-width: 829px) {
    left: -3.3rem;
  }
}

.carousel__next {
  background: url('~@/assets/img/icon-next.png') no-repeat 0 0 / 100% 100%;
  right: 0;
  @media (min-width: 829px) {
    right: -3.3rem;
  }
}

.carousel__pagination {
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 1.4rem;
  @media (min-width: 829px) {
    display: none;
  }
}

.carousel__pagination-item {
  height: 3rem;
  width: 2.8rem;
  ~ .carousel__pagination-item {
    margin-left: 2.9rem;
  }
}

.carousel__pagination-button {
  background: url('~@/assets/img/slider-dot.png') no-repeat 0 0 / 100% 100%;
  height: 100%;
  width: 100%;
}

.carousel__pagination-button--active {
  background: url('~@/assets/img/slider-dot-active.png') no-repeat 0 0 / 100% 100%;
}

/* preregistration */

#preregistration {
  .box-title {
    margin: 0 auto;
    width: 56.5rem;
    @media (min-width: 829px) {
      width: 52.1rem;
    }
  }
}

.preregistration-item {
  margin: 4.6rem auto 0;
  width: 62rem;
  @media (min-width: 829px) {
    margin-top: 1.5rem;
    width: 42.1rem;
  }
}

.preregistration-list {
  margin: 6.2rem auto 0;
  width: 44.2rem;
  @media (min-width: 829px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5.1rem;
    width: 44.7rem;
  }
  li {
    cursor: pointer;
    @media (min-width: 829px) {
      width: 21.8rem;
    }
    ~ li {
      margin-top: 2rem;
      @media (min-width: 829px) {
        margin-top: 1.1rem;
      }
    }
    &:nth-child(2) {
      @media (min-width: 829px) {
        margin-top: 0;
      }
    }
  }
}

.preregistration-confirm,
.preregistration-invalid,
.preregistration-complete {
  h3 {
    margin: 0 auto;
    + .box-text {
      margin-top: 3rem;
    }
  }
}

.preregistration-mail {
  margin-top: 6.6rem;
  @media (min-width: 829px) {
    margin-top: 4.2rem;
  }
  .box-check {
    margin-top: 11.9rem;
    @media (min-width: 829px) {
      margin-top: 5.7rem;
    }
  }
}

.preregistration-confirm {
  h3 {
    width: 45rem;
    @media (min-width: 829px) {
      width: 32.5rem;
    }
  }
  .box {
    padding-bottom: 5rem;
    @media (min-width: 829px) {
      padding-bottom: 4.3rem;
    }
  }
}

.preregistration-confirm-mail {
  color: #b4a076;
  font-size: 3rem;
  margin-top: 23.4rem;
  text-align: center;
  @media (min-width: 829px) {
    margin-top: 8.2rem;
  }
}

.preregistration-now {
  display: block;
  margin: 16.9rem auto 0;
  width: 57.8rem;
  @media (min-width: 829px) {
    margin-top: 9.3rem;
    width: 40rem;
  }
}

.preregistration-invalid h3 {
  width: 54.4rem;
  @media (min-width: 829px) {
    width: 39.2rem;
  }
}

.preregistration-complete h3 {
  width: 52.9rem;
  @media (min-width: 829px) {
    width: 38.3rem;
  }
}

/* twitter */
.section-left,
.section-left > div,
.section-right {
  @media (min-width: 829px) {
    width: 50.7rem;
    margin: 0;
  }
}

#twitter .box-title {
  margin: 0 auto;
  width: 54.6rem;
  @media (min-width: 829px) {
    width: 46.2rem;
  }
}

.twitter-box {
  height: 65rem;
  margin: 5rem auto 0;
  overflow-y: scroll;
  width: 66rem;
  &::-webkit-scrollbar {
    width: 1.2rem;
    height: 0;
    @media (min-width: 829px) {
      width: 1rem;
    }
  }
  &::-webkit-scrollbar-track {
    background: linear-gradient(to right, rgba(47,46,43,0) 0%,rgba(47,46,43,0) 28%,rgba(47,46,43,1) 28%,rgba(47,46,43,1) 72%,rgba(47,46,43,0) 72%,rgba(47,46,43,0) 100%);
  }
  &::-webkit-scrollbar-thumb {
    min-height: 7.1rem;
    background: url('~@/assets/img/twitter-scrollBar.png') no-repeat 0 0 / 100% 100%;
    @media (min-width: 829px) {
      min-height: 5.4rem;
    }
  }
  @media (min-width: 829px) {
    height: 28rem;
    margin-top: 2.6rem;
    width: 45.9rem;
  }
}

#twitter .twitter-button {
  display: block;
  margin: 2rem auto 0;
  width: 39.6rem;
  @media (min-width: 829px) {
      width: 19rem;
  }
}

/* campaign */

#campaign .box-title {
  margin: 0 auto;
  width: 68.2rem;
  @media (min-width: 829px) {
      width: 46.4rem;
  }
}

#campaign .contents {
  li {
    width: 62rem;
    margin: 2rem auto 0;
    border: solid 1px #73674f;
    @media (min-width: 829px) {
      margin-top: 2.6rem;
      width: 42rem;
    }
    &:nth-of-type(1) {
      margin-top: 5.8rem;
      @media (min-width: 829px) {
        margin-top: 3.9rem;
      }
    }
  }
}

/* notes */

#notes .box-title {
  margin: 0 auto;
  width: 49.2rem;
  @media (min-width: 829px) {
      width: 39.4rem;
  }
}

#notes .box-list {
  margin: 2.9rem auto 0;
  width: 62rem;
  color: #8e8e8e;
  font-size: 2rem;
  line-height: 1.818;
  @media (min-width: 829px) {
      width: 96rem;
      font-size: 1.4rem;
  }
  li {
    position: relative;
    margin-top: 2.8rem;
    padding-left: 1em;
    text-indent: -.5em;
    @media (min-width: 829px) {
      margin-top: .1rem;
    }
    &::before {
      content: "※";
      position: relative;
      display: inline-block;
    }
  }
}
</style>

<script>
import axios from 'axios'
import YouTube from 'vue3-youtube'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

const userAgent = navigator.userAgent

export default {
  components: {
    YouTube,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  data() {
    return {
      scrollY: 0,
      showMovie: false,
      playerVars: {
        playsinline: 1
      },
      homeBackground: false,
      settings: {
        itemsToShow: 1
      },
      breakpoints: {
        829: {
          itemsToShow: 3
        }
      },
      registMail : "",
      inputActive: true,
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      validEmailError : false,
      urlParams : "",
      referrer : "",
      agreechecked : false,
      errorMessage : "※正しいメールアドレスをご入力ください。",
      showContent1 : false,
      showContent2 : false,
      showContent3 : false,
      iframeSrc : "",
      banners: []
    }
  },
  watch: {
    registMail(val) {
      if (val === '') {
        this.inputActive = true
      } else {
        this.inputActive = false
      }
      if (this.validEmail(this.registMail)) {
        this.validEmailError = false
      } else {
        this.errorMessage = "※正しいメールアドレスをご入力ください。"
        this.validEmailError = true
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
        if (userAgent.match(/iPhone/i) || userAgent.match(/iPad/i) || userAgent.match(/iPod/i)) {
          return 'ios'
        } else {
          return 'android'
        }
      } else {
        return false
      }
    },
    calculateScrollY() {
      scrollY = window.scrollY
    },
    scrollFixed() {
      const body = document.body
      this.calculateScrollY()
      body.style.top = -scrollY + 'px'
      body.classList.add('is-fixed')
    },
    scrollActive() {
      const body = document.body
      body.style.top = 0
      body.classList.remove('is-fixed')
      document.documentElement.scrollTop = scrollY
    },
    handleScroll() {
      if (!this.homeBackground) {
        const top = this.$el.getBoundingClientRect().top;
        this.homeBackground = top < window.innerHeight - 1200;
      } else if (window.pageYOffset < 200) {
        this.homeBackground = false;
      }
    },
    openMovie(e) {
      e.preventDefault()
      this.scrollFixed()
      this.showMovie = true
    },
    closeMovie() {
      this.showMovie = false
      this.scrollActive()
      this.stopVideo()
    },
    stopVideo() {
      const iframe = document.getElementsByTagName('iframe');
      const iframeDocument = iframe[0].contentWindow;
      iframeDocument.postMessage('{"event":"command","func":"'+"pauseVideo"+'","args":""}', '*');
    },
    onReady() {
      this.$refs.youtube.playVideo()
    },
    onReadyBackground() {
      this.$refs.youtube.mute()
      this.$refs.youtube.playVideo()
    },
    returnPreregistration(e) {
      const element = document.getElementById('preregistration')
      const rect = element.getBoundingClientRect();
      const elementTop = rect.top + window.pageYOffset;
      e.preventDefault()
      window.scrollTo({
        top: elementTop - 40,
        behavior: 'smooth'
      })
    },
    openConfirm() {
      if(!this.validEmail(this.registMail)) {
        this.errorMessage = "※正しいメールアドレスをご入力ください。"
        this.validEmailError = true
      } else if(!this.agreechecked) {
        this.errorMessage = "注意事項に同意してください。"
        this.validEmailError = true
      } else {
        this.showContent1 = true
      }
    },
    prereg() {
      if(!this.agreechecked) {
        this.errorMessage = "注意事項に同意してください。"
        this.validEmailError = true
      } else {
        let formData = new FormData();
        formData.append('email', this.registMail);
        formData.append('param', this.urlParams);
        formData.append('referer', this.referrer);

        axios.post( '/api/preReg/preReg.aspx',
          formData,
        )
        .then(response => {
          console.log('SUCCESS!!'+response.data);
          if(response.data.data == "1") {

            fbq('track', 'CompleteRegistration');

            _lt('send', 'cv', {
              type: 'Conversion'
            },['43d1c29c-fb98-4e6e-89fb-0d95e7a64c4e']);
            /*
            axios.get('/reg_email.html').then((Response)=>{
                //console.log(Response.data);
            }).catch((Error)=>{
                console.log(Error);
            })
            */
           this.iframeSrc = "/reg_email.html"

            this.showContent1 = false
            this.showContent2 = false
            this.showContent3 = true
            this.registMail = ""
          } else {
            this.showContent1 = false
            this.showContent2 = true
            this.showContent3 = false
          }
        })
        .catch(function(error){
          console.log('FAILURE!!'+error)
        });
      }      
    },
    etcRegBtnClick(regtype) {
      let formData = new FormData();
      formData.append('regtype', regtype);
      formData.append('param', this.urlParams);
      formData.append('referer', this.referrer);

      axios.post('/api/preReg/etcRegBtnClick.aspx',
        formData,
      ).then((Response)=>{
          //console.log(Response.data);
      }).catch((Error)=>{
          console.log(Error);
      })
    },
    googleBtnClick() {
      this.etcRegBtnClick("Google")
      location.href="/reg_google.html"
    },
    twitterBtnClick() {
      this.etcRegBtnClick("Twitter")
      location.href="/reg_twitter.html"
    },
    lineBtnClick() {
      this.etcRegBtnClick("Line")
      location.href="/reg_line.html"
    },
    appleBtnClick() {
      this.etcRegBtnClick("Line")
      location.href="/reg_apple.html"
    },
    validEmail: function (email) {
      return this.reg.test(email);
    },
    getBannerInfo() {
      axios.get( '/api/banner/topbanner.aspx?bType=1',
        )
        .then(response => {
          //console.log('SUCCESS!!'+response.data);
          this.banners = response.data
        })
        .catch(function(error){
          console.log('FAILURE!!'+error)
        });
    },
    async fetchYoutube() {
      await (this.isOpen = true);
      this.$refs.youtube.fetchData();
    },
    onReady() {
      const player = this.$refs.youtube.player
      player.mute()
      player.playVideo()
    }
  },
  computed: {
    player() {
      return this.$refs.youtube.player
    }
  },
  mounted: function () {
    //get ad param
    this.urlParams = window.location.search
    this.referrer = document.referrer
    this.getBannerInfo()

    setTimeout(() => {
      const tweetScript = document.createElement('script')
      tweetScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
      tweetScript.setAttribute('async', 'true')
      document.head.appendChild(tweetScript)

      const hero = document.getElementById('hero')
    }, 100)

    setTimeout(() => {
      hero.classList.add('is-active')
    }, 1000)
  }
}
</script>
