<template>
  <div class="cbt">
    <section id="cbt-hero">
      <h1><img class="cbt-hero-title" src="@/assets/img/cbt-hero-title.png" loading="lazy" alt="先行体験傭兵団員募集!"></h1>
      <img class="cbt-logo" src="@/assets/img/cbt-logo.png" loading="lazy" alt="セブンナイツ2">
      <img class="cbt-hero-text" src="@/assets/img/cbt-hero-text.png" loading="lazy" alt="事前登録開始を記念して「セブンナイツ2」を事前プレイできる先行体験団員を募集!誰よりも早く傭兵団員!">
      <a class="cbt-hero-button" @click="regbtnclick"><img src="@/assets/img/cbt-button.png" loading="lazy" alt="先行体験傭兵団員に応募する"></a>
    </section>
    <section id="date">
      <h2 class="box-title"><img src="@/assets/img/cbt-date-title.png" loading="lazy" alt="応募期間"></h2>
      <p class="box-text">10月14日<span class="small">(木)</span>～10月20日<span class="small">(水)</span> 23:59まで</p>
    </section>
    <section id="guidance">
      <h2 class="box-title"><img src="@/assets/img/cbt-guidance-title.png" loading="lazy" alt="当落案内"></h2>
      <p class="box-text">抽選のうえ10月22日(金)から当選された方へメールで順次ご連絡いたします。落選のご連絡は差し上げませんのであらかじめご了承ください。</p>
    </section>
    <section id="mission">
      <h2 class="box-title"><img src="@/assets/img/cbt-mission-title.png" loading="lazy" alt="ミッション"></h2>
      <p class="box-text">応募者から抽選で選ばれる「先行体験傭兵団員」には、
        「Twitter投稿」や「アリーナランキング」など多種多様なミッションが用意されています。
        ミッションを達成した方には、Amazonギフト券のコードや、ゲーム内で利用できる豪華アイテムなどGETできるチャンス！<br>
        <br>
        詳細は当選案内時に合わせてご案内いたします。</p>
    </section>
    <section id="spec">
      <h2 class="box-title"><img src="@/assets/img/cbt-spec-title.png" loading="lazy" alt="動作環境"></h2>
      <div class="table">
        <table>
          <colgroup>
            <col class="w29per" span="1">
            <col class="w71per" span="1">
          </colgroup>
          <tbody>
            <tr class="dark-cell">
              <th>iOS</th>
              <td>iOS10.0 以上</td>
            </tr>
            <tr class="light-cell">
              <th>Android</th>
              <td>
                <p class="caution">先行体験傭兵団員対象外です。<span class="sp"></span>あらかじめご了承ください。</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <section id="caution">
      <h2 class="box-title"><img src="@/assets/img/cbt-caution-title.png" loading="lazy" alt="先行体験に関する注意事項"></h2>
      <ul class="box-list">
        <li>先行体験傭兵団員の募集はiOSだけで実施いたします。</li>
        <li>先行体験のプレイデータは正式サービス開始前に削除されます｡データ引き継ぎはできません｡</li>
        <li>先行体験では有料アイテムの購入はできません。</li>
        <li>先行体験の利用に伴う通信料は､先行体験傭兵団員が負担するものとします｡</li>
        <li>先行体験の実施手順は、今回ご登録いただくメールアドレスへ届きます。</li>
        <li>先行体験へのご応募はお一人様につき1回のみです。</li>
        <li>先行体験の実施および募集内容などは予告なく変更する場合がございます。</li>
        <li>動作対応機種の場合でも、先行体験傭兵団員のご利用状況やその他の要因で正常に動作しない場合がございます。</li>
        <li>先行体験傭兵団員に生じた一切の損害、損失、不利益等に関し、当社はいかなる責任をも負いません。</li>
        <li>先行体験に参加いただく際にはゲーム内にて別途利用規約に同意いただきます。</li>
        <li>先行体験の募集や抽選に関するお問い合わせには、お応えできません。</li>
        <li>先行体験プレイの権利を第三者へ譲渡することはできません。</li>
        <li>個人情報のお取扱いについて当社は、当キャンペーンに際して参加者よりご提供いただいた個人情報を当キャンペーンの運営に必要な範囲内で利用し、第三者に開示いたしません。個人情報のお取扱いについては、当社の<a href="//help.netmarble.com/terms/privacy_policy_ja_inc" target="_blank" rel="noopener">プライバシーポリシー</a>をご参照ください。</li>
        <li>先行体験期間中は、サービス内容および取得したアイテム等のすべてのデータを予告なく変更する場合がございます。</li>
        <li>先行体験期間中は予告なく中止、中断、メンテナンスを行う場合がございます。</li>
      </ul>
    </section>
    <p class="copy-text"><img src="@/assets/img/cbt-copy-text.png" loading="lazy" alt="今すぐ傭兵団員に参加しよう！"></p>
    <a class="cbt-button" @click="regbtnclick"><img src="@/assets/img/cbt-button.png" loading="lazy" alt="先行体験傭兵団員に応募する"></a>
  </div>
</template>
<script>
export default {
  methods:{
    regbtnclick() {
      alert('先行体験傭兵団員の募集は終了いたしました。\n当選案内につきましては、10/22よりメールで順次ご連絡いたします。')
    }
  }
}
</script>

<style lang="scss">
.table {
    margin: 0 auto;
		width: 76.4rem;
	}
	table {
		width: 100%;
		background-color: transparent;

		.w29per { width: 29%;}
		.w71per { width: 71%;}

		th, td {
      padding: 3.5rem 0 3.2rem;
			border-top: solid 1px #1b1a1a;
			color: #cfdad3;
			font-size: 2.8rem;
      font-weight: 400;
      @media (min-width: 829px) {
        padding: 2.4rem 0;
        font-size: 1.6rem;
      }
		}
    th {
      font-weight: bold;
      text-align: center;
      }
    td {
      text-align: left;
      }
    tr:nth-of-type(2) {
      th, td {
          border-top: solid 1px #1b1a1a;
        }
    }
    .caution {
      position: relative;
      // margin-top: 1.6rem;
      color: #8e8e8e;
      font-size: 2rem;
      padding-left: 1em;
      text-indent: -.5em;
      @media (min-width: 829px) {
        // margin-top: .8rem;
        font-size: 1.2rem;
      }
      &::before {
        content: "※";
        display: inline-block;
        position: relative;
      }
    }
    .dark-cell {
      background-color: #272626;
    }
    .light-cell {
      background-color: #2c2b2b;
    }
	}

.cbt {
  background: #1b1a1a;
  font-family: 'Noto Sans JP';
  font-weight: 400;
  color: #cfdad3;
  padding-bottom: 21rem;
  @media (min-width: 829px) {
    padding-bottom: 12.2rem;
  }
  .box-title {
    margin: 16rem auto 0;
    @media (min-width: 829px) {
      margin: 7.8rem auto 0;
    }
  }
}

/* hero */

#cbt-hero {
  position: relative;
  background: url('~@/assets/img/cbt-visual-sp.jpg') no-repeat 0 0 / 100%;
  height: 130rem;
  @media (min-width: 829px) {
    background: url('~@/assets/img/cbt-visual-pc.jpg') no-repeat 50% 0 / 192rem;
    height: 98rem;
  }
}

.cbt-hero-title,
.cbt-logo,
.cbt-hero-text,
.cbt-hero-button {
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  filter: grayscale(100%);
}

.cbt-hero-title {
  width: 33.6rem;
  top: 34.4rem;
  margin: 0 auto 0 4.2rem;
  @media (min-width: 829px) {
    width: 28.8rem;
    top: 27.2rem;
    left: calc( 50% - 62rem );
    margin: 0;
  }
}

.cbt-logo {
  width: 33.4rem;
  top: 10.9rem;
  margin: 0 auto 0 4.7rem;
  @media (min-width: 829px) {
    width: 30.2rem;
    top: 5.7rem;
    left: calc( 50% - 62.5rem );
    margin: 0;
  }
}

.cbt-hero-text {
  width: 65.8rem;
  top: 80.2rem;
  @media (min-width: 829px) {
    width: 54.2rem;
    top: 62.7rem;
  }
}

.cbt-hero-button {
  width: 57.8rem;
  top: 97rem;
  @media (min-width: 829px) {
    width: 43.6rem;
    top: 76.1rem;
  }
}

/* date */
#date {
  .box-title {
    margin-top: 14rem;
    width: 56.6rem;
    @media (min-width: 829px) {
      margin-top: 7.8rem;
      width: 52.2rem;
    }
  }
  .box-text {
    margin-top: 4.8rem;
    font-size: 4rem;
    font-weight: bold;
    @media (min-width: 829px) {
      margin-top: 3.3rem;
      font-size: 3rem;
    }
    .small {
      font-size: 3.2rem;
      @media (min-width: 829px) {
        font-size: 2.4rem;
      }
    }
  }
}

/* guidance */
#guidance {
  .box-title {
    width: 56.6rem;
    @media (min-width: 829px) {
      width: 52.2rem;
    }
  }
  .box-text {
    margin: 5.6rem auto 0;
    width: 76.4rem;
    font-size: 2.8rem;
    text-align: left;
    @media (min-width: 829px) {
      margin-top: 3.8rem;
      width: 56rem;
      font-size: 1.6rem;
    }
  }
}

/* mission */
#mission {
  .box-title {
    width: 56.6rem;
    @media (min-width: 829px) {
      width: 52.2rem;
    }
  }
  .box-text {
    margin: 5.6rem auto 0;
    width: 76.4rem;
    font-size: 2.8rem;
    text-align: left;
    @media (min-width: 829px) {
      margin-top: 3.8rem;
      width: 56rem;
      font-size: 1.6rem;
    }
  }
}

/* spec */
#spec {
  .box-title {
    width: 56.6rem;
    @media (min-width: 829px) {
      width: 52.2rem;
    }
  }
  .table {
    margin-top: 3.8rem;
    @media (min-width: 829px) {
        margin-top: 2.7rem;
    }
  }
  .sp {
    display: block;
    @media (min-width: 829px) {
      display: none;
    }
  }
}

/* caution */
#caution {
  .box-title {
    width: 42.2rem;
    @media (min-width: 829px) {
      width: 32.6rem;
    }
  }
  .box-list {
    margin: 6rem auto 0;
    width: 76.4rem;
    font-size: 2.4rem;
    color: #8e8e8e;
    line-height: 1.5;
    @media (min-width: 829px) {
      margin-top: 3.8rem;
      width: 82.4rem;
      font-size: 1.2rem;
    }
    li {
      position: relative;
      padding-left: 1em;
      text-indent: -.5em;
      &::before {
        content: "・";
        position: relative;
        display: inline-block;
      }
    }
    a {
      color: #8e8e8e;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.copy-text {
  position: relative;
  width: 66rem;
  margin: 14.8rem auto 0;
  @media (min-width: 829px) {
      margin-top: 9.4rem;
      width: 53rem;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 47.2rem;
    height: 26.7rem;
    background: url('~@/assets/img/cbt-copy-text-ef.png') no-repeat 0 0 / 100%;
    top: -9.7rem;
    left: calc( 50% - 47.2rem/2 );
    mix-blend-mode: screen;
    @media (min-width: 829px) {
      width: 35.3rem;
      height: 20rem;
      top: -6.9rem;
      left: calc( 50% - 35.3rem/2 );
    }
  }
}

.cbt-button {
  display: block;
  margin: 4.3rem auto 0;
  width: 57.8rem;
  @media (min-width: 829px) {
    width: 43.6rem;
    margin-top: 6.6rem;
  }
  filter: grayscale(100%);
}

</style>