import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import World from '../views/World.vue'
import System from '../views/System.vue'
import Preplay from '../views/Preplay.vue'
import PathNotFound from '../views/404.vue'
import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

const defaultTitle = 'セブンナイツ2'
const defaultDescription = 'セブンナイツシリーズの新作『セブンナイツ2』 『セブンナイツ』の世界観や面白さを継承して進化したRPG'
const defaultImage = 'https://sevenknights2.netmarble.jp/img/ogp.jpg'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: defaultTitle,
      description: defaultDescription,
      image: defaultImage
    }
  },
  {
    path: '/world/',
    name: 'World',
    component: World,
    meta: {
      title: 'セブンナイツ2 | 世界観',
      description: defaultDescription,
      image: defaultImage
    }
  },
  {
    path: '/system/',
    name: 'System',
    component: System,
    meta: {
      title: 'セブンナイツ2 | ゲームシステム',
      description: defaultDescription,
      image: defaultImage
    }
  },
  {
    path: '/preplay/',
    name: 'Preplay',
    component: Preplay,
    meta: {
      title: 'セブンナイツ2 | 先行体験傭兵団員募集！',
      description: defaultDescription,
      image: 'https://sevenknights2.netmarble.jp/img/ogp-preplay.jpg'
    }
  },
  {
    path: '/:catchAll(.*)',
    name:'PathNotFound',
    component: PathNotFound
  }
]

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else if (to.hash) {
    return {
      el: to.hash
    }
  } else {
    return { top: 0 }
  }
};

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior
})

router.afterEach((to, from) => {
  const setTitle = to.meta.title || defaultTitle
  const setDescription = to.meta.description || defaultDescription
  const setImage = to.meta.image || defaultImage

  document.title = setTitle
  document.querySelector('meta[property$="description"]').setAttribute('content', setDescription)
  document.querySelector('meta[name$="description"]').setAttribute('content', setDescription)
  document.querySelector('meta[property$="image"]').setAttribute('content', setImage)
  document.querySelector('meta[name$="image"]').setAttribute('content', setImage)

  document.querySelector('meta[property$="title"]').setAttribute('content', setTitle)
  document.querySelector('meta[name$="title"]').setAttribute('content', setTitle)
})

export default router
