<template>
  <div id="loading">
    <div class="loading-img"><img src="@/assets/img/loading-cover.png" loading="lazy" alt="loading"></div>
  </div>
</template>

<style lang="scss">
#loading {
  align-items: center;
  background: #000;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 9999;
  p {
    color: #fff;
    text-align: center;
  }
  .loading-img {
    position: relative;
    width: 50.2rem;
    width: 0 auto;
    @media (min-width: 829px) {
      width: 41.6rem;
    }
  }
}
</style>
