<template>
  <footer id="footer">
    <div class="spec">
      <img class="spec-icon" src="@/assets/img/app.png" alt="セブンナイツ2">
      <table class="spec-list">
        <tr>
          <th>タイトル</th>
          <td>セブンナイツ2</td>
        </tr>
        <tr>
          <th>ジャンル</th>
          <td>キャラクターコレクション<span class="br"></span>シネマティックRPG</td>
        </tr>
        <tr>
          <th>対応端末</th>
          <td>
            <ul>
              <li>Galaxy Note5と同水準または<span class="br"></span>それ以上のスペックを持つ端末<br>
                (Android 7.0以上/メモリ3GB以上)<br>
                ※一部端末を除く
              </li>
              <li>iPhone 6S（iOS 10.0以上）</li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>価格</th>
          <td>基本プレイ無料(アプリ内課金あり)</td>
        </tr>
      </table>
    </div>
    <div class="global">
      <div class="sns">
        <ul>
          <li><a href="//twitter.com/Sena2_JP" target="_blank" rel="noopener"><svg><use xlink:href="#iconTwitter"></use></svg></a></li>
          <li><a href="//www.youtube.com/channel/UCrRdcVCaEMNqE2xWgDCXdUg/" target="_blank" rel="noopener"><svg><use xlink:href="#iconYoutube"></use></svg></a></li>
        </ul>
      </div>
      <ul class="link">
        <li><a href="//help.netmarble.com/terms/privacy_policy_ja_inc" target="_blank" rel="noopener">プライバシーポリシー</a></li>
        <li><a href="//www.netmarble.co.jp/spec/" target="_blank" rel="noopener">推奨環境</a></li>
        <li><a href="mailto:help_sk2@netmarble.jp" target="_blank" rel="noopener">お問い合わせ</a></li>
      </ul>
      <p class="copy"><small>Apple および Apple ロゴは米国その他の国で登録された Apple Inc. の商標です。<br>
      App Store は Apple Inc. のサービスマークです。<br>
      Google Play および Google Play ロゴは、Google LLC の商標です。<br>
      © Netmarble Corp. & Netmarble Nexus Inc. 2021 All Rights Reserved.</small></p>
    </div>
    <svg class="svgPath">
      <symbol id="iconTwitter" viewBox="0 0 100 100">
          <path d="M31.4,90.6c37.7,0,58.4-31.3,58.4-58.4c0-0.9,0-1.8-0.1-2.7c4-2.9,7.5-6.5,10.2-10.6c-3.7,1.6-7.6,2.7-11.8,3.2c4.2-2.5,7.5-6.6,9-11.3c-4,2.4-8.4,4.1-13,5c-3.7-4-9.1-6.5-15-6.5c-11.3,0-20.5,9.2-20.5,20.5c0,1.6,0.2,3.2,0.5,4.7c-17.1-0.9-32.2-9-42.3-21.4c-1.8,3-2.8,6.6-2.8,10.3c0,7.1,3.6,13.4,9.1,17.1c-3.4-0.1-6.5-1-9.3-2.6c0,0.1,0,0.2,0,0.3c0,9.9,7.1,18.2,16.5,20.1c-1.7,0.5-3.5,0.7-5.4,0.7c-1.3,0-2.6-0.1-3.9-0.4c2.6,8.2,10.2,14.1,19.2,14.2c-7,5.5-15.9,8.8-25.5,8.8c-1.7,0-3.3-0.1-4.9-0.3C9.1,87.2,19.9,90.6,31.4,90.6" />
      </symbol>
      <symbol id="iconYoutube" viewBox="0 0 100 100">
        <path d="M93.8,27.8c-1.1-4-4.2-7.1-8.1-8.1C78.6,17.8,50,17.8,50,17.8s-28.6,0-35.8,1.9c-3.9,1.1-7,4.2-8.1,8.1C4.2,35,4.2,50,4.2,50s0,15,1.9,22.2c1.1,4,4.2,7.1,8.1,8.1c7.1,1.9,35.8,1.9,35.8,1.9s28.6,0,35.8-1.9c3.9-1.1,7-4.2,8.1-8.1C95.8,65,95.8,50,95.8,50S95.8,35,93.8,27.8z M40.6,63.6V36.4L64.6,50L40.6,63.6z"/>
      </symbol>
    </svg>
  </footer>
</template>

<style lang="scss">
@import url('//www.netmarble.co.jp/common/footer.css');

#footer {
  background: #141414;
  .global {
    margin-top: -24.4rem;
    padding-top: 0;
    position: relative;
    @media (min-width: 829px) {
      margin-top: 0;
    }
  }
  .sns li {
    @media (min-width: 829px) {
      background: #4f4f4f;
    }
    svg {
      fill: #4f4f4f;
      @media (min-width: 829px) {
        fill: #282828;
      }
    }
  }
  .link li {
    &::before {
      background: #9b9b9b;
    }
    a {
      color: #9b9b9b;
    }
  }
  .copy {
    color: #434343;
  }
}

.spec {
  margin: 0 auto;
  padding-top: 1px;
  position: relative;
  width: 72.4rem;
  @media (min-width: 829px) {
    width: 63.9rem;
  }
  @media (max-width: 375px) {
    width: 76.9rem;
  }
  .spec-icon {
    left: 0;
    position: absolute;
    top: 11.8rem;
    width: 20rem;
    border-radius: 2rem;
    @media (min-width: 829px) {
      top: 5.6rem;
      width: 12.2rem;
      border-radius: 1rem;
    }
  }
  .spec-list {
    border: 1px solid #585f6e;
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'メイリオ', Osaka, 'MS PGothic', sans-serif;
    margin: 0 0 0 auto;
    transform: scale(.5, .5) translate3d(0, -25.2rem, 0);
    transform-origin: right middle;
    width: 474px;
    @media (min-width: 829px) {
      margin: 2.9rem 0 1rem auto;
      transform: scale(1, 1) translate3d(0, 0, 0);
      width: 50rem;
    }
    @media (max-width: 375px) {
      width: 460px;
    }
    th,
    td {
      border: 1px solid #585f6e;
      color: #aaa;
      font-size: 18px;
      line-height: 1.333;
      padding: 16px 20px;
      @media (min-width: 829px) {
        font-size: 1.2rem;
        padding: 8px 20px;
      }
      @media (max-width: 375px) {
        padding: 16px;
      }
    }
    th {
      font-weight: 400;
      vertical-align: top;
      width: 140px;
      @media (min-width: 829px) {
        width: 90px;
      }
    }
    td {
      text-align: left;
    }
  }
}

</style>
