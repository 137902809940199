<template>
  <header id="header">
    <a class="header-logo" href="//www.netmarble.co.jp/" target="_blank" rel="noopener"><img src="//www.netmarble.co.jp/common/logo_netmarble_w.svg" loading="lazy" alt="Netmarble"></a>
  </header>
</template>

<style lang="scss">
#header {
  height: 40px;
  left: 0;
  padding: 12px 16px;
  position: absolute;
  top: 0;
  z-index: 10;
  @media (min-width: 829px) {
    padding: 10px 20px;
  }
}

.header-logo {
  display: block;
  height: 16px;
  width: 92px;
  @media (min-width: 829px) {
    height: 20px;
    width: 115px;
  }
}
</style>
