<template>
  <div class="sticky">
    <router-link class="sticky-button" to="/#preregistration"><img src="@/assets/img/button-click.png" loading="lazy" alt="事前登録はこちらから"></router-link>
  </div>
</template>

<style lang="scss">
.sticky {
  background: url('~@/assets/img/sticky-background-sp.png') no-repeat 0 0 / 100% 100%;
  bottom: -1px;
  height: 19.7rem;
  margin-top: -2rem;
  padding-top: 4.7rem;
  position: -webkit-sticky;
  position: sticky;
  @media (min-width: 829px) {
    background: url('~@/assets/img/sticky-background-pc.png') no-repeat 50% 0 / auto 100%;
    height: 12.7rem;
    padding-top: 3.9rem;
  }
}

.sticky-button {
  display: block;
  margin: 0 auto;
  width: 52rem;
  @media (min-width: 829px) {
    width: 30.4rem;
  }
}
</style>
